import './App.css';
import Header from './components/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSubmissionSettings } from './firestore/firestore';
import Info from './pages/Info';
import ReplicateDrawing from './pages/ReplicateDrawing';
import Closed from './pages/Closed';
import Claiming from './pages/Claiming';
import Judge from './pages/Judge';
import Judged from './pages/Judged';
import AvgJudged from './pages/Avgjudged';
import Drawing from './pages/Drawing';
import Mint from './pages/Mint';
import CheckAllowance from './pages/CheckAllowance';
import ParticipantMint from './pages/ParticipantMint';
import { useEthers } from '@usedapp/core';
import PublicSale from './pages/PublicSale';


function App() {
  const [picklesSubmitted, setPicklesSubmitted] = useState(null);
  const { chainId } = useEthers();
  const [judges, setJudges] = useState([]);
  const [isArtist, setIsArtist] = useState(null);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    getSubmissionSettings().then(data => {
      setPicklesSubmitted(data.counter);
      setJudges(data.judges);
    })
  },[])

  useEffect(() => {
    if (chainId && chainId !== 1) {
        alert("You are in wrong network please switch to Ethereum Mainnet")
    }
  }, [chainId])

  return (
    <BrowserRouter>
        <div className="App">
        <Header isArtist={isArtist} />
    <Routes>
      <Route path="/" element={<PublicSale />}/>
      <Route path="/draw" element={<Closed picklesSubmitted={picklesSubmitted}/>} />
      <Route path="/info" element={<Info isArtist={isArtist} picklesSubmitted={picklesSubmitted} setPicklesSubmitted={setPicklesSubmitted}/>} />
      <Route path="/replicate/:id" element={<ReplicateDrawing isAllowed={isAllowed} setIsAllowed={setIsAllowed} picklesSubmitted={picklesSubmitted} isArtist={isArtist} setPicklesSubmitted={setPicklesSubmitted} setIsArtist={setIsArtist}/>}/>
      <Route path="/closed" element={<Closed picklesSubmitted={picklesSubmitted}/>} />
      <Route path="/claim" element={<Claiming picklesSubmitted={picklesSubmitted}/>} />
      <Route path="/judge/:id" element={<Judge judges={judges} />} />
      <Route path="/judged/:vote" element={<Judged judges={judges} />} />
      <Route path="/avg-judged/:vote" element={<AvgJudged judges={judges} />} />
      <Route path="/admin-draw" element={<Drawing judges={judges} picklesSubmitted={picklesSubmitted} isArtist={isArtist} setPicklesSubmitted={setPicklesSubmitted} setIsArtist={setIsArtist}/>} />
      {/* <Route path="/mint" element={<Mint />} /> */}
      <Route path='/check-allowance' element={<CheckAllowance isArtist={isArtist}/>}/>
      <Route path='/participants-mint' element={<ParticipantMint />} />
      <Route path='/public-sale' element={<PublicSale />} />
    </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
