import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { useEthers, useLookupAddress } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEffect, useState } from "react";
import Web3Modal from 'web3modal';
import { getMyInvites } from "../firestore/firestore";
import InvitationModal from "./InvitationModal";

export const sort0xAddress = (address) => {
  if (address.length < 16) return address;

  return `${address.slice(0, 2)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

const ConnectWallet = ({isArtist}) => {
  const { activateBrowserWallet, account, activate } = useEthers();
  const { ens } = useLookupAddress(account)
  const connectWallet = async () => {
    if (window?.ethereum?.isMetaMask) {
      await activateBrowserWallet();
    } else {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: '39971fe2ad00481bb2b331d3fed8366d',
          },
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: 'Pickles',
            infuraId: '39971fe2ad00481bb2b331d3fed8366d',
          },
        },
      };
      const web3Modal = new Web3Modal({
        network: 'mainnet', // optional
        cacheProvider: true, // optional
        providerOptions, // required
      });

      const provider = await web3Modal.connect();
      await activate(provider);
    }
  }

  const [invites, setInvites] = useState(0);
  const [showInvitatoinModal, setShowInvitationModal] = useState(false);
  useEffect(() => {
    if (account) {
      getMyInvites(account).then((inv) => {
        setInvites(inv?.length != null ? isArtist - inv?.length: 0);
      });
    }
  }, [account, isArtist])
  if (account) {

    return (
      <div>
        {invites > 0 && (<div onClick={() => setShowInvitationModal(true)} className="text-secondary" style={{
          position: "absolute",
          top: "23px",
          right: "128px",
          borderBottom: "2px solid",
          cursor: "pointer"
        }}>
        {invites} invites
        </div>)}
      <button className="connectWallet reversed">
      {sort0xAddress(ens ?? account)}
    </button>
    {showInvitatoinModal && <InvitationModal setShowInvitationModal={setShowInvitationModal}/>}
    </div>
    )
  }
 return (
  <button id="connectWalletButton" onClick={() => connectWallet()} className="connectWallet">
    Connect Wallet
  </button>
 )
}

export default ConnectWallet;
