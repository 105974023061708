import WaitingPng from '../assets/Busy-Pickle-Gif.gif';
import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/discord.svg';
import { ReactComponent as MustardIcon } from '../assets/mustard.svg';
import { useState } from 'react';
import TraitNameModal from '../components/TraitNameModal';
import { useEthers } from '@usedapp/core';

const Closed = ({picklesSubmitted}) => {
  const [showTraitModal, setShowTraitModal] = useState(false);
  const { account } = useEthers();
  return (
    <div style={{
      position:"absolute",
      top:0,
      left:0,
      bottom:0,
      right:0,
      paddingTop: "150px",
      zIndex:-1
    }}>
      {(showTraitModal && account)&& <TraitNameModal setShowTraitModal={setShowTraitModal} isArtist={true}/>}
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent:"space-between",
      height: "calc(100vh - 150px)",
      paddingBottom: '20px',
      position:"relative"
    }}>
    <div className="text-secondary" style={{
      fontSize: "20px",
      display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
      position: "relative",
      top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
    }}>

      <img src={WaitingPng} alt="waiting pickle" style={{maxWidth: "164px", width: '100%'}}/>
      <p style={{
        fontSize: "20px",
        textAlign: "center"
      }}>Drawing window is closed.<br/>Submissions are being reviewed.</p>
      <p>Apply <div style={{fontWeight: 700, color: "#40b03d",textDecoration: "underline", cursor: "pointer" , display: "inline-block"}} onClick={()=> setShowTraitModal(true)}>here</div> to change the "artist-name" trait associated with your submissions</p>
      <p>
        Follow on <a style={{
          textDecoration: "none",
          borderBottom: "1px solid"
        }} className="text-secondary" target="_new" href="https://twitter.com/PicklePeopleNFT">twitter</a> + <a style={{
          textDecoration: "none",
          borderBottom: "1px solid"
        }} className="text-secondary" href="https://discord.com/invite/jUBCf6pMZX" target="_new">discord</a> for updates.
      </p>
    </div>
    <div style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "450px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "auto"
      }}>
<div style={{visibility: picklesSubmitted != null ? "visible" : "hidden"}} className="text-secondary"><strong>{picklesSubmitted}</strong> pickles submitted</div>
        <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
        <TwitterIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://discord.com/invite/jUBCf6pMZX">
        <DiscordIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://mustardlabs.io">
        <MustardIcon className="text-secondary"/>
        </a></div>
      </div>
      </div>
      </div>
  )
};

export default Closed;