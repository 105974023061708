import { useEthers } from "@usedapp/core"
import { useEffect, useState } from "react";
import {  Link, useParams } from "react-router-dom";
import {  getJudgementsByVote, getSubmissionsByAvgVote } from "../firestore/firestore";

const AvgJudged = ({judges}) => {
  const { account } = useEthers();
  const { vote } = useParams();
  const [drawings, setDrawings] = useState([]);

  useEffect(() => {
    if (!account) return;
    getSubmissionsByAvgVote(Number(vote)).then(data => setDrawings(data))
  },[vote, account])


  if (!account || !judges.includes(account.toLowerCase())) {
    console.log('no judge', account, judges)
    return null;
  }

  return (
    <div style={{
      position:"absolute",
      top:0,
      left:0,
      bottom:0,
      right:0,
      paddingTop: "150px",
      zIndex:-1
    }}>
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent:"space-between",
      height: "calc(100vh - 150px)",
      paddingBottom: '20px',
      position:"relative"
    }}>
      <div style={{
        display: "flex", flexWrap:"wrap", gap: "15px", padding: "20px"
      }}>
    {drawings.map((item, idx) => (
      <div key={idx} style={{textAlign: "center"}}>
        <Link to={'/judge/'+item.assignedNumber}>
      <p style={{color: "white"}}>{item.name} - Avg : {item.avgVote}</p>
      <img src={item.image} alt="pickle" style={{width: "100%", maxWidth: "384px"}}/>
      </Link>
      </div>
    ))}
    </div>
      </div>
      </div>
  )
};

export default AvgJudged;
