import { Goerli, Mainnet } from '@usedapp/core';
import { getDefaultProvider } from 'ethers';

export const CHAIN_ID = 1;
const infuraId = process.env.REACT_APP_INFURA_KEY;

const config = {
  readOnlyChainId: CHAIN_ID,
  readOnlyUrls: {
    [Mainnet.chainId]: infuraId
      ? 'https://mainnet.infura.io/v3/' + infuraId
      : getDefaultProvider('mainnet'),
  }, 
  notifications: {
    expirationPeriod: 5000,
  },
};

export default config;
