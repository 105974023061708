import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/discord.svg';
import { ReactComponent as MustardIcon } from '../assets/mustard.svg';
import { useEffect, useState } from 'react';
import TraitsExample from '../assets/traits_example.png'
import { useEthers } from '@usedapp/core';
import { getArtistWhitelists, getUnlimitedPass, getWhitelists, isInvited } from '../firestore/firestore';
import TraitNameModal from '../components/TraitNameModal';
import whitelist from '../helpers/whitelist_quota.json';

const CheckAllowance = () => {
  const [mintNumber,setMintNumber] = useState(0);
  const { account } = useEthers();
  const [showTraitModal,setShowTraitModal] = useState(false);

  const checkWhitelist = async () => {
    const found = whitelist.find(item => item[0] ===account.toLowerCase())[1];
    if (found) {
      setMintNumber(found);
      return;
    }
    setMintNumber(0);
  }

  useEffect(() => {
    if (account) {
      checkWhitelist()
    }

  }, [account])

  if (!account) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent:"space-between",
        height: "calc(100vh - 150px)",
        paddingBottom: '20px',
        
      }}>
            <div className="text-secondary" style={{
      fontSize: "20px",
      display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
      position: "relative",
      top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
    }}>
    <div style={{color:"white"}}>Please connect your wallet</div>
    </div>
    </div>
    )
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent:"space-between",
      height: "calc(100vh - 150px)",
      paddingBottom: '20px',
      
    }}>
      {(showTraitModal && account)&& <TraitNameModal setShowTraitModal={setShowTraitModal} isArtist={true}/>}
    <div className="text-secondary" style={{
      fontSize: "20px",
      display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
      position: "relative",
      top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
    }}>
      <div style={{color:"white"}}>You will be able to mint {mintNumber} pickles</div>
      {mintNumber > 0 && (<div style={{color:"white", textAlign: "center"}} >Apply <span style={{borderBottom: "1px solid white", cursor:"pointer"}} onClick={() => setShowTraitModal(true)}>here</span> to change the artist trait on your submission< br/><span style={{opacity: 0.4}}>(wallet/ENS used automatically)</span></div>)}
      {mintNumber > 0 && (<div>
        <img src={TraitsExample} alt="trait example"/>
        <div style={{opacity: 0.6, fontSize: "14px", color: "white", textAlign: "center"}}>This is not to change the name of your pickle*</div>
      </div>)}
    </div>
    <div style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "450px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "auto",
        marginTop: "20px"
      }}>
        <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
        <TwitterIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://discord.com/invite/jUBCf6pMZX">
        <DiscordIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://mustardlabs.io">
        <MustardIcon className="text-secondary"/>
        </a></div>
      </div>
      </div>
  )
}

export default CheckAllowance;
