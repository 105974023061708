import poappickleAbi from './abi/PoapPickle.json';
import salePickleAbi from './abi/salePickles.json';
import  auctionPicklesAbi from './abi/auctionPickles.json';
import { Network, Alchemy } from 'alchemy-sdk';
import { ethers } from 'ethers';

export const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);
export const TOKEN_CONTRACT = process.env.REACT_APP_PICKLEPEOPLE_CONTRACT;
export const POAPPICKLE_CONTRACT = process.env.REACT_APP_POAPPICKLE_CONTRACT;
export const PICKLE_AUCTION_CONTRACT = process.env.REACT_APP_AUCTION_SALE_CONTRACT;

export const POAPPICKLE_ABI = poappickleAbi;
export const PICKLEPEOPLE_ABI = salePickleAbi;
export const AUCTIONPICKLE_ABI = auctionPicklesAbi;

const settings =
  CHAIN_ID === 1
    ? {
        apiKey: 'C3TQE3JEcky_r8zfz0y08_YQShTY4y7P',
        network: Network.ETH_MAINNET,
      }
    : {
        apiKey: 'H-sX5kEtCodlE_aVFwr6ZKW9vMANyAWU',
        network: Network.ETH_GOERLI,
      };
const alchemy = new Alchemy(settings);

export const getEvents = async () => {
  const events = await alchemy.core.getLogs({
    address: PICKLE_AUCTION_CONTRACT,
    fromBlock: 'earliest',
  });
  return events.filter(
    it =>
      it.topics.includes(
        '0x0f6798a560793a54c3bcfe86a93cde1e73087d944c0ea20544137d4121396885'
      ) ||
      it.topics.includes(
        '0xaec8e13f64207044c453b2a37afb225aee4220504c856bb67504075327026c08'
      )
  );
};

export const decodeEvents = (events) => {
  return events.map((event) => {
    //mint
    if (
      event.topics[0] ===
      '0x0f6798a560793a54c3bcfe86a93cde1e73087d944c0ea20544137d4121396885'
    ) {
      const wallet =  ethers.utils.defaultAbiCoder.decode(
        ['address', 'uint256'],
        event.data
      )[0]
      const price = Number(
        ethers.utils.defaultAbiCoder.decode(
          ['address', 'uint256'],
          event.data
        )[1]
      );
      return { ...event, wallet, price };
    }
    return {};
  })
};