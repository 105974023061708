import { useEthers, useLookupAddress, useResolveName } from "@usedapp/core"
import { useEffect, useState } from "react";
import { submitNameTrait } from "../firestore/firestore";

const TraitNameModal = ({setShowTraitModal, isArtist}) => {
  const [invited, setInvited] = useState(false);
  const [name, setName] = useState("");
  const [linkToTweet, setLinkToTweet] = useState(null);
  const [discordId, setDiscordId] = useState(null);

  useEffect(() => {
    if (invited) {
      setTimeout(() => window.location.reload(), 1000)
    }
  }, [invited]);

  if (!isArtist) {return (
<>
<div 
    onClick={()=> {invited ? window.location.reload() :setShowTraitModal(false)}}
    style={{
      position: "fixed",
      zIndex:4000,
      top:0,
      bottom:0,
      left:0,
      right:0,
      background: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(2px)",
    }}>
      <div
      onClick={(e) => e.stopPropagation()}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#102F17",
        padding: "30px",
        width: "100%",
        maxWidth: "346px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        borderRadius: "10px",
        justifyContent: "start",
        alignItems: "start"
      }}>
<h2 className="text-secondary" style={{margin: 0, fontFamily: "PixelFont", fontSize: "14px", textAlign:"center", width: "100%", marginBottom: "20px"}}>Apply for name trait</h2>
        <p className="text-secondary"  style={{margin: 0, fontSize: "10px"}}>Only wallets on the allowlist can change their name trait.</p>
        <button className="text-secondary submitDraw" onClick={() => setShowTraitModal(false)}>Close</button>
        </div></div>
</>

  );}

  return (
    <div 
    onClick={()=> {invited ? window.location.reload() :setShowTraitModal(false)}}
    style={{
      position: "fixed",
      zIndex:4000,
      top:0,
      bottom:0,
      left:0,
      right:0,
      background: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(2px)",
    }}>
      <div
      onClick={(e) => e.stopPropagation()}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#102F17",
        padding: "30px",
        width: "100%",
        maxWidth: "346px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        borderRadius: "10px",
        justifyContent: "start",
        alignItems: "start"
      }}>
        {!invited && (
          <>

        <h2 className="text-secondary" style={{margin: 0, fontFamily: "PixelFont", fontSize: "14px", textAlign:"center", width: "100%", marginBottom: "20px"}}>Apply for name trait</h2>
        <p className="text-secondary"  style={{margin: 0, fontSize: "10px"}}>Submitted pickles will be give traits of your wallet / ens.<br/> To use a different name, apply here:</p>
        <input placeholder="New Name" type="text" value={name} onChange={(e) => setName(e.target.value)}  style={{
          background: "rgba(0,0,0,0.5)",
          color: "white",
          textAlign: "left",
          border: "none",
          borderRadius: "20px",
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          fontSize: "10px",
          paddingLeft: "15px"
        }}/>
        <p className="text-secondary"  style={{margin: 0, fontSize: "10px"}}>We must verify that you are who you say you are.</p>
        <p className="text-secondary"  style={{margin: 0, fontSize: "10px"}}>It will help to provide a link to a tweet that mentions the pickle project, and/or your discord id. (both optional).</p>
        <input placeholder="Link to Tweet (optional)" type="text" value={linkToTweet} onChange={(e) => setLinkToTweet(e.target.value)}  style={{
          background: "rgba(0,0,0,0.5)",
          color: "white",
          textAlign: "left",
          border: "none",
          borderRadius: "20px",
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          fontSize: "10px",
          paddingLeft: "15px"
        }}/>
        <input placeholder="Discord ID (optional)" type="text" value={discordId} onChange={(e) => setDiscordId(e.target.value)}  style={{
          background: "rgba(0,0,0,0.5)",
          color: "white",
          textAlign: "left",
          border: "none",
          borderRadius: "20px",
          padding: "10px",
          marginBottom: "20px",
          width: "100%",
          fontSize: "10px",
          paddingLeft: "15px"
        }}/>
        <SubmitButton setInvited={setInvited} name={name} discordId={discordId} linkToTweet={linkToTweet} setShowTraitModal={setShowTraitModal}/>
        </>
        )}
        {invited && <h2 className="text-secondary" style={{marginInline: "auto",fontFamily: "PixelFont", fontSize: "14px", textAlign: "center"}}>Application sent!</h2>}
      </div>
    </div>
  )
}

const SubmitButton = ({ name, linkToTweet, discordId, setShowTraitModal, setInvited }) => {
  const {account} = useEthers();
  const { ens } = useLookupAddress(account);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    if (!name) {
      alert("Please inpute a new name.");
      setLoading(false);
      return;
    }

    const created = submitNameTrait({account, name, linkToTweet, discordId, ens})
    if (created) {
      setInvited(true);
    } else {
      setShowTraitModal(false);alert("There was an error!");setLoading(false);
    }
  }

  return (
    <>
    <button className="text-secondary submitDraw" style={{margin: 0, display: "flex", gap: "10px", marginInline: "auto", justifyContent: "center"}} onClick={() => handleSubmit()}>{loading && <div className="spinner">C</div>}Submit</button>

    </>
  )
}

export default TraitNameModal;
