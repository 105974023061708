import ConnectWalletGif from '../assets/connect_wallet_gif.gif';
import ConnectWalletBG from '../assets/connect_wallet_bg.png';
import ConnectWalletText from '../assets/connect_wallet_text.png';
import ConnectWalletGifNotAllowed from '../assets/connect_wallet_not_allowed.png';

const ConnectWalletImage = ({notAllowed}) => (
  <div style={{
    position: "absolute",
    top:0,
    zIndex:-4
  }}>
    <img src={ConnectWalletBG} alt="background" style={{width: "100%"}}/>
    <img src={ConnectWalletGif} alt="text" style={{position: "absolute", top: 0, left: 0, width: "100%", zIndex:2}} />
    <img src={notAllowed ? ConnectWalletGifNotAllowed :ConnectWalletText} alt="text" style={{position: "absolute", top: "50%", left: "50%", width: "70%", zIndex:4, opacity: 0.15, transform:"translate(-50%,-50%)"}} />
  </div>
);

export default ConnectWalletImage;
