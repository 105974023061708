import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/discord.svg';
import { ReactComponent as MustardIcon } from '../assets/mustard.svg';
import { ReactComponent as OpenseaIcon } from '../assets/opensea.svg';
import { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import TraitNameModal from '../components/TraitNameModal';
import whitelist from '../helpers/whitelist.json';
import whitelistQuota from '../helpers/whitelist_quota.json';
import PickleImage from '../assets/changing-pickle.gif';
import { Contract } from 'ethers';
import { PICKLEPEOPLE_ABI, TOKEN_CONTRACT } from '../web3/constants';
import { Link } from 'react-router-dom';

const ParticipantMint = () => {
  const [mintNumber,setMintNumber] = useState(0);
  const [amountToMint, setAmountToMint] = useState(0);
  const { account, library } = useEthers();
  const [showTraitModal,setShowTraitModal] = useState(false);

  const checkWhitelist = async () => {
    const found = whitelistQuota.find(item => item[0] ===account.toLowerCase());
    const contractInstance = new Contract(TOKEN_CONTRACT, PICKLEPEOPLE_ABI, library);
    const minted = await contractInstance.minted(account).then((data) => Number(data)).catch(() => 0);
    if (found) {
      setMintNumber(found[1]-minted);
      setAmountToMint(found[1]-minted);
      return;
    }
    setMintNumber(-1);
    setAmountToMint(-1);
  }

  useEffect(() => {
    if (account && library) {
      checkWhitelist()
    }

  }, [account])

  const [countDown, setCountDown] = useState();
  useEffect(() => {
    const countDownDate = new Date('Dec 20, 2022 17:00:00.000Z').getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      var hours = Math.floor(distance  / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance > 0) {
        setCountDown(`${hours}:${minutes}:${seconds}`);
      } else {
        setCountDown(null);
      }
    }, 1000)
    return () => clearInterval(x);
  },[])

  const handleAmount = (num) => {
    if (num+amountToMint > 0 && num+amountToMint <= mintNumber) {
      setAmountToMint((prev) => prev+num);
    } 
  }

  const handleMint = () => {
    const found = whitelistQuota.find(item => item[0] ===account.toLowerCase());
    const contractInstance = new Contract(TOKEN_CONTRACT, PICKLEPEOPLE_ABI, library.getSigner())
    console.log(whitelist[account.toLowerCase()], found[1], amountToMint)
    contractInstance.mint(whitelist[account.toLowerCase()], found[1],amountToMint, {
      gasLimit: 160000 + amountToMint*50000
    });
  }

    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent:"space-between",
        height: "calc(100vh - 150px)",
        paddingBottom: '20px',
        
      }}>
            <div className="text-secondary" style={{
      fontSize: "20px",
      display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
      position: "relative",
      top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
    }}>
    <div style={{color:"white"}}>Participants Mint has been finished. Please visit <Link to="/public-sale" style={{color: "white"}}>Public <u>Sale</u></Link></div>
    </div>
    </div>
    )

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent:"space-between",
      height: "calc(100vh - 150px)",
      paddingBottom: '20px',
      
    }}>
      {(showTraitModal && account)&& <TraitNameModal setShowTraitModal={setShowTraitModal} isArtist={true}/>}
    <div className="text-secondary" style={{
      fontSize: "20px",
      display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
      position: "relative",
      top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
    }}>
      {countDown && (<><div>Free participant mint closes in <b>{countDown}</b></div>
      <div>(remaining pickles will be mintable via <u>public sale</u>)</div>
      </>)}
      <div className="drawWrapper">
      <p>You can mint <b>{mintNumber}</b> pickles</p>
      <img src={PickleImage} alt="pickle" style={{width: "100%"}}/>
      <div className="text-secondary" style={{
        display: "flex", gap: "40px",
        alignItems: "center"
        }}>
      <button onClick={() => handleAmount(-1)} style={{
        fontSize: "40px",
        lineHeight: "30px",
        borderRadius: '30px',
        background: '#358933',
        width: "32px",
        height: "32px",
        color: "#0b2110"
      }}>
        -
      </button>
      <b style={{fontSize: "30px"}}>{amountToMint}</b>
      <button onClick={() => handleAmount(1)} style={{
        fontSize: "34px",
        lineHeight: "30px",
        borderRadius: '30px',
        background: '#358933',
        width: "32px",
        height: "32px",
        color: "#0b2110"
      }}>
        <div style={{
          marginLeft:"-1px",
          marginTop: "-3px"
        }}>+</div>
      </button>
      </div>
      <button disabled={!mintNumber} onClick={handleMint} className="text-secondary submitDraw" style={{width: "200px"}}>
        Mint
      </button>
      </div>
    </div>
    <div style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "450px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "auto",
        marginTop: "20px"
      }}>
      <div><a href="https://opensea.io/collection/pickle-people" target="_new">
        <OpenseaIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
        <TwitterIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://discord.com/invite/jUBCf6pMZX">
        <DiscordIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://mustardlabs.io">
        <MustardIcon className="text-secondary"/>
        </a></div>
      </div>
      </div>
  )
}

export default ParticipantMint;
