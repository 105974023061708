import { NavLink, useLocation  } from "react-router-dom";
import ConnectWallet from "./ConnectWallet";

const Header = ({ isArtist }) => {
  const location = useLocation();
  
  return (
    <header className="bg-secondary text-secondary">
      <div className="mainHeader">
        <div className="hideMobile" style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "10px",
          flex: 1,
          paddingRight: "10px",
          opacity: 0.3,
          pointerEvents: "none"
        }}>
          <NavLink to="/participants-mint" className="pixelArt text-secondary" style={{
            borderBottom: location.pathname === '/participants-mint' ? '2px solid': "none"
          }}>Participant</NavLink>
                    <NavLink to="/participants-mint" className="pixelArt text-secondary" style={{
            borderBottom: location.pathname === '/participants-mint' ? '2px solid': "none"
          }}>Mint</NavLink>
        </div>
        <div className="hideMobile" style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "10px",
          flex: 1,
        }}>
          <NavLink to="/public-sale" className="pixelArt text-secondary" style={{
            borderBottom: !["/gallery","/claim","/info", '/mint', '/participants-mint'].includes(location.pathname) ? '2px solid': "none",
          }}>Public Sale</NavLink>
        </div>
        <div className="logo" style={{
          flex:1.2
        }}>
          <NavLink to="/" style={{textDecoration: "none"}}>
          <div>
          <span style={{color: "#21742E"}}>P</span>
          <span style={{color: "#298A38"}}>I</span>
          <span style={{color: "#256724"}}>X</span>
          <span style={{color: "#3B7421"}}>E</span>
          <span style={{color: "#338C52"}}>L</span>
          <span style={{color: "#427A4B"}}>A</span>
          <span style={{color: "#358933"}}>T</span>
          <span style={{color: "#386528"}}>E</span>
          <span style={{color: "#2B8A3A"}}>D</span>
          </div>
          <div>
          <span style={{color: "#367421"}}>P</span>
          <span style={{color: "#247731"}}>I</span>
          <span style={{color: "#377023"}}>C</span>
          <span style={{color: "#1A6742"}}>K</span>
          <span style={{color: "#278737"}}>L</span>
          <span style={{color: "#3B7421"}}>E</span>
          </div>
          <div>
          <span style={{color: "#257E34"}}>P</span>
          <span style={{color: "#386528"}}>E</span>
          <span style={{color: "#22673A"}}>O</span>
          <span style={{color: "#21742E"}}>P</span>
          <span style={{color: "#368D54"}}>L</span>
          <span style={{color: "#347D40"}}>E</span>
          </div>
          </NavLink>
        </div>
        <div className="hideMobile" style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "10px",
          flex: 1,
        }}>
          <NavLink to="/claim" className="pixelArt text-secondary" style={{
            borderBottom: location.pathname === "/claim" ? '2px solid': "none"
          }}>Claim</NavLink>
        </div>
        <div className="hideMobile" style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "10px",
          flex: 1
        }}>
          <NavLink to="/info" className="pixelArt text-secondary" style={{
            borderBottom: location.pathname === "/info" ? '2px solid': "none"
          }}>INFO</NavLink>
        </div>
      </div>
      <ConnectWallet isArtist={isArtist}/>
  </header>
  )
};

export default Header;
