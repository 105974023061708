import React, { useEffect } from "react";
import "../../styles/row.css";
import Pixel from "./Pixel";

export default function Row(props) {
  const { saveToHistory, width, selectedColor, steadyHand, drawHistory, setDrawHistory , index, randomBGNumber, randomPickleNumber } = props;
  let pixels = [];
  
  for (let i = 0; i < width; i++) {
    pixels.push(
    <Pixel
      key={i}
      selectedColor={selectedColor}
      steadyHand={steadyHand}
      index={i}
      rowIndex={index}
      saveToHistory={saveToHistory}
      drawHistory={drawHistory}
      setDrawHistory={setDrawHistory}
      randomBGNumber={randomBGNumber}
      randomPickleNumber={randomPickleNumber}
    />
    );
  }

  return <div className="row">{pixels}</div>;
}
