import React, { forwardRef, useEffect, useState } from "react";
import "../../styles/drawingCanvas.css";
import Row from "./Row";

import { checkNameDuplication, getArtistWhitelists, getMySubmissions, getUnlimitedPass, getWhitelists, isInvited } from "../../firestore/firestore";
import { useEthers } from "@usedapp/core";
import { randomBGs } from "../../helpers/backgrounds";
import ConnectWalletImage from "../ConnectWalletImage";

const DrawingPanel = forwardRef((props, ref,) => {
  const { setShowGrid, isAllowed, setIsAllowed, saveToHistory, setSaveToHistory, width, height, selectedColor, setIsArtist, clickAndDrag, name, setName, randomBGNumber, randomPickleNumber, setDrawBlob, setIsUploading, isUploading } = props;
  const randomBG = `/img/bg/${randomBGs[randomBGNumber]}`
  const randomPickle = `/img/pickles/_${randomPickleNumber}.png`
  const { account } = useEthers();
  const [totalSubmissions, setTotalSubmissions] = useState(null);
  const [currentSubmissions, setCurrentSubmissions] = useState(null);
  const [subName, setSubName] = useState("");
  const [drawHistory, setDrawHistory] = useState([]);

  useEffect(() => {
    if (drawHistory?.length > 0) {
      localStorage.setItem("pickleDrawHistory", JSON.stringify(drawHistory));
    }
    if (drawHistory.length === 0) {
      const obj = JSON.parse(localStorage.getItem("pickleDrawHistory"));
      if (obj && obj.length !== 0) {
        if (account) {
          setDrawHistory(obj);
        }

      }
    }
  }, [drawHistory, account]);

  const checkWhitelist = async () => {
    const gotInvite = await isInvited(account);
    const whitelisted = await getWhitelists(account);
    const artist = await getArtistWhitelists(account);
    const unlimited = await getUnlimitedPass(account);
    if (unlimited) {
      setTotalSubmissions(8888);
      setIsArtist(10000);
      return;
    }
    if (artist) {
      setTotalSubmissions(20);
      setIsArtist(10);
      return;
    }
    if (whitelisted || gotInvite) {
      setTotalSubmissions(4);
      setIsArtist(2);
      return;
    }
    setIsArtist(false);
    setTotalSubmissions(0);
  }
  useEffect(() => {
    if (account) {
      checkWhitelist();
      getMySubmissions(account).then((data) => setCurrentSubmissions(data.length || 0));
    }
  },[account]);

  let rows = [];

  for (let i = 0; i < height; i++) {
    rows.push(<Row key={i} width={width} selectedColor={selectedColor} steadyHand={clickAndDrag} setDrawHistory={setDrawHistory} drawHistory={drawHistory} saveToHistory={saveToHistory && totalSubmissions > 0}index={i} randomBGNumber={randomBGNumber} randomPickleNumber={randomPickleNumber}/>);
  }

  const handleSubmit = async (ref) => {
    if (name) {
      if(!(await checkNameDuplication(name))) {
        alert('That name is already taken, please try something else. Get creative!');
        return;
      }
    }
    if (!name) {
      alert("Error: You must name your pickle before you can submit.");
      return;
    }
    setShowGrid(false);
    setDrawBlob(true);
    setIsUploading(true);
  }

  const shouldShowSubmissions = totalSubmissions != null && currentSubmissions != null;

  useEffect(() => {
    if (isUploading) {
      setCurrentSubmissions(prev => prev +1);
    }
  }, [isUploading])

  useEffect(() => {
    if (subName !== "") {
      setName(subName+ " Pickle");
    }
  }, [subName]);

  useEffect(() => {
    if (totalSubmissions === 0) {
      setSaveToHistory(false);
    }
  }, [totalSubmissions])

  return (
    <div id="drawingPanel" style={{position: "relative"}}>
                {false && (
            <div style={{
              background: "rgba(0,0,0,0.4)",
              backdropFilter: "blur(8px)",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              zIndex: 10,
              height: 11.6*48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              aspectRatio: "1:1",
              color: "white",
              fontWeight: 700,
              fontSize: "20px"
            }}>
              Connecting to Wallet.
            </div>
          )}
            <div style={{
          position: "relative",
          width: 8*48,
          zIndex:-1,
        }}>
{!randomPickleNumber && account && <ConnectWalletImage />}
{account && totalSubmissions === 0 && <ConnectWalletImage notAllowed />}
{randomPickleNumber && totalSubmissions > 0 && <img src={randomPickle} onLoad={() => {setSaveToHistory(true)}} alt="Drawing pickle" style={{
      position: "absolute",
      width: 8*48,
      height: 8*48,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex:-4
    }}/>}
            </div>
      <div style={{
          position: "relative",
          width: 8*48,
          zIndex:-2
        }}>
        {randomBGNumber !== undefined && totalSubmissions > 0 && <img src={randomBG} alt="Drawing background" style={{
      position: "absolute",
      width: 8*48,
      height: 8*48,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex:-1
        }}/>}

        </div>
      <div id="pixels" ref={ref}>
      {rows}
      {!account && <ConnectWalletImage />}
      </div>
      {account && totalSubmissions > 0 &&<div className="namePickle" style={{
        display: "flex",
        gap: "5px",
        justifyContent: "center"
      }}>
        <div style={{display:"flex", gap: "5px"}}>
      <input pattern="[A-Za-z]" type="text" maxLength={15}
      style={{ overflow:"visible", width:"120px",background: "none", padding: 0,
      border: "none", color: "rgba(255,255,255,0.5)", fontSize: "12px", fontFamily: "Inter", fontWeight: 400, textAlign: "right"}}
       value={subName}
      onChange={(e) => {
        if (e.target.value === "") setSubName("")
        if(/^[A-Za-z0-9_ ]+$/.test(e.target.value))setSubName(e.target.value)}
      }
       placeholder="Name Your"/>
       <div style={{fontWeight: 400, textAlign:"left",width:"80px"}}>Pickle</div>
       </div>
       </div>}
      {account &&  totalSubmissions > 0 && <button disabled={!account || !shouldShowSubmissions || currentSubmissions >= totalSubmissions || isUploading} onClick={() => handleSubmit(ref)} className="submitDraw text-secondary">
        SUBMIT
      </button>}
      {!account && <div onClick={() => document.getElementById("connectWalletButton").click()} className="text-secondary" style={{marginTop: "15px", fontSize: "14px", marginBottom: "20px", fontWeight: 600, textAlign:"center"}}>Try out the drawing workspace!<br/>Connect your wallet to be assigned a pickle.</div>}
      {(account && totalSubmissions > 0) && <div className="text-secondary" style={{
        marginTop: '11px',
        visibility: shouldShowSubmissions ? "visible" : "hidden"
      }}>Submitted <strong>{currentSubmissions} / {totalSubmissions}</strong> pickles</div>}
      {(account && totalSubmissions === 0) && <div className="text-secondary" style={{
        marginTop: '11px',
        color: "white",
        textAlign: "center",
        fontWeight: 600,
      }}>You are not on the allowlist.
      <div className="text-secondary" style={{
        marginTop: "10px",
        fontWeight: 400
      }}>Get an invite, or keep an eye on our twitter for chances to gain access.</div>
      </div>}
    </div>
  );
});

export default DrawingPanel;
