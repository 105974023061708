export const gradientBGs = [
  'blue-pink-gradient.png',
  "green-lime-gradient.png",
  "green-pink-gradient.png",
  "orange-blue-gradient.png",
  "pink-lilac-gradient.png",
  "pink-lime-gradient.png",
  "purple-blue-gradient.png",
  "red-yellow-gradient.png",
  "teal-peach-gradient.png",
];

export const noiseBGs = [
  "beige-noise.png",
  "blue-noise.png",
  "brine-noise.png",
  "cyan-noise.png",
  "dark-green-noise.png",
  "dull-green-noise.png",
  "forest-noise.png",
  "green-noise.png",
  "grey-noise.png",
  "khaki-noise.png",
  "leaf-noise.png",
  "mint-noise.png",
  "orange-noise.png",
  "pale-green-noise.png",
  "pink-noise.png",
  "red-noise.png",
  "teal-noise.png",
  "yellow-noise.png",
];

export const solidBGs = [
  "brine-solid.png",
  "brown-solid.png",
  "cyan-solid.png",
  "dark-green-solid.png",
  "dull-green-solid.png",
  "forest-solid.png",
  "green-solid.png",
  "khaki-solid.png",
  "leaf-solid.png",
  "light-pink-solid.png",
  "magenta-solid.png",
  "midnight-solid.png",
  "mint-solid.png",
  "navy-solid.png",
  "pale-green-solid.png",
  "peach-solid.png",
  "redish-brown-solid.png",
  "wine-solid.png",
];

export const gifBGs = [
  "orange-gif.gif",
  "purple-gif.gif",
  "toxic-gif.gif",
  "white-gif.gif",
];

export const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const randomBGs = [
  "beige-noise.png",
  "brown-solid.png",
  "dark-green-noise.png",
  "teal-peach-gradient.png",
  "red-noise.png",
  "magenta-solid.png",
  "peach-solid.png",
  "magenta-solid.png",
  "light-pink-solid.png",
  "midnight-solid.png",
  "dull-green-noise.png",
  "beige-noise.png",
  "forest-solid.png",
  "redish-brown-solid.png",
  "cyan-noise.png",
  "mint-noise.png",
  "orange-gif.gif",
  "green-solid.png",
  "green-noise.png",
  "peach-solid.png",
  "wine-solid.png",
  "orange-blue-gradient.png",
  "orange-noise.png",
  "pink-noise.png",
  "khaki-solid.png",
  "brown-solid.png",
  "blue-pink-gradient.png",
  "purple-gif.gif",
  "brown-solid.png",
  "blue-noise.png",
  "midnight-solid.png",
  "navy-solid.png",
  "magenta-solid.png",
  "navy-solid.png",
  "mint-solid.png",
  "blue-pink-gradient.png",
  "dark-green-noise.png",
  "orange-noise.png",
  "pink-noise.png",
  "khaki-solid.png",
  "navy-solid.png",
  "forest-noise.png",
  "forest-noise.png",
  "dull-green-solid.png",
  "green-noise.png",
  "green-noise.png",
  "beige-noise.png",
  "dull-green-noise.png",
  "teal-noise.png",
  "leaf-noise.png",
  "khaki-noise.png",
  "teal-noise.png",
  "dark-green-solid.png",
  "redish-brown-solid.png",
  "pale-green-solid.png",
  "dark-green-noise.png",
  "redish-brown-solid.png",
  "dark-green-noise.png",
  "green-solid.png",
  "brine-noise.png",
  "brown-solid.png",
  "brine-noise.png",
  "red-noise.png",
  "yellow-noise.png",
  "cyan-noise.png",
  "red-noise.png",
  "grey-noise.png",
  "teal-noise.png",
  "leaf-solid.png",
  "magenta-solid.png",
  "pink-lime-gradient.png",
  "dull-green-noise.png",
  "brine-solid.png",
  "brine-solid.png",
  "purple-blue-gradient.png",
  "wine-solid.png",
  "khaki-solid.png",
  "magenta-solid.png",
  "peach-solid.png",
  "midnight-solid.png",
  "leaf-solid.png",
  "navy-solid.png",
  "dull-green-noise.png",
  "wine-solid.png",
  "pale-green-solid.png",
  "purple-blue-gradient.png",
  "green-solid.png",
  "khaki-solid.png",
  "mint-noise.png",
  "khaki-noise.png",
  "green-noise.png",
  "brine-noise.png",
  "mint-solid.png",
  "red-noise.png",
  "blue-noise.png",
  "cyan-solid.png",
  "light-pink-solid.png",
  "light-pink-solid.png",
  "midnight-solid.png",
  "blue-noise.png",
  "pale-green-noise.png",
  "redish-brown-solid.png",
  "pink-lilac-gradient.png",
  "pale-green-noise.png",
  "orange-blue-gradient.png",
  "green-solid.png",
  "green-lime-gradient.png",
  "peach-solid.png",
  "grey-noise.png",
  "dark-green-solid.png",
  "midnight-solid.png",
  "dark-green-solid.png",
  "khaki-solid.png",
  "red-yellow-gradient.png",
  "pale-green-noise.png",
  "leaf-noise.png",
  "navy-solid.png",
  "pale-green-solid.png",
  "leaf-solid.png",
  "pink-lime-gradient.png",
  "dull-green-solid.png",
  "brine-solid.png",
  "grey-noise.png",
  "forest-solid.png",
  "red-yellow-gradient.png",
  "forest-noise.png",
  "pale-green-solid.png",
  "redish-brown-solid.png",
  "dull-green-solid.png",
  "mint-noise.png",
  "blue-noise.png",
  "mint-solid.png",
  "dull-green-solid.png",
  "green-solid.png",
  "wine-solid.png",
  "cyan-solid.png",
  "pale-green-solid.png",
  "light-pink-solid.png",
  "mint-solid.png",
  "navy-solid.png",
  "orange-noise.png",
  "cyan-noise.png",
  "forest-noise.png",
  "redish-brown-solid.png",
  "dark-green-solid.png",
  "cyan-solid.png",
  "forest-solid.png",
  "leaf-solid.png",
  "khaki-noise.png",
  "dark-green-solid.png",
  "khaki-noise.png",
  "white-gif.gif",
  "green-pink-gradient.png",
  "wine-solid.png",
  "leaf-solid.png",
  "brine-solid.png",
  "brown-solid.png",
  "magenta-solid.png",
  "leaf-solid.png",
  "teal-peach-gradient.png",
  "green-solid.png",
  "pink-noise.png",
  "dark-green-solid.png",
  "light-pink-solid.png",
  "mint-solid.png",
  "cyan-solid.png",
  "forest-solid.png",
  "cyan-solid.png",
  "peach-solid.png",
  "peach-solid.png",
  "dull-green-solid.png",
  "brine-solid.png",
  "brine-noise.png",
  "yellow-noise.png",
  "mint-noise.png",
  "pale-green-noise.png",
  "dull-green-solid.png",
  "midnight-solid.png",
  "pale-green-solid.png",
  "leaf-noise.png",
  "green-pink-gradient.png",
  "cyan-noise.png",
  "mint-solid.png",
  "forest-solid.png",
  "green-lime-gradient.png",
  "wine-solid.png",
  "brine-solid.png",
  "pink-noise.png",
  "pink-lilac-gradient.png",
  "leaf-noise.png",
  "light-pink-solid.png",
  "yellow-noise.png",
  "brown-solid.png",
  "forest-solid.png",
  "teal-noise.png",
  "yellow-noise.png",
  "khaki-solid.png",
  "grey-noise.png",
  "cyan-solid.png",
  "beige-noise.png",
  "toxic-gif.gif",
  "orange-noise.png"
];