import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/discord.svg';
import { ReactComponent as MustardIcon } from '../assets/mustard.svg';
import { ReactComponent as OpenseaIcon } from '../assets/opensea.svg';
import { useEffect, useState } from 'react';
import { formatDistance } from 'date-fns';
import { useEthers, useLookupAddress } from '@usedapp/core';
import PickleImage from '../assets/changing-pickle.gif'
import { Contract, ethers } from 'ethers';
import { AUCTIONPICKLE_ABI, decodeEvents, getEvents, PICKLE_AUCTION_CONTRACT } from '../web3/constants';
import MintInfo from '../components/MintInfo';
import { sort0xAddress } from '../components/ConnectWallet';
import { getSubmissionSettings } from '../firestore/firestore';

const PublicSale = () => {
  const { account, library } = useEthers();
  const [currentPrice, setCurrentPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [isDecresing, setIsDecresing] = useState(null);


  const handleMint = () => {
    if (!account) {alert("Please connect your wallet first");
    return;}
    if (!remaining) {alert("We are sorry but there are no more pickles for mint.")
    return;
  }
    const contractInstance = new Contract(PICKLE_AUCTION_CONTRACT, AUCTIONPICKLE_ABI, library.getSigner())
    contractInstance.mint( account, {
      value: ethers.utils.parseUnits((currentPrice).toString()).toString(),
      gasLimit: 170000
    });
  }

  const getPrice = () => {
    const contractInstance = new Contract(PICKLE_AUCTION_CONTRACT, AUCTIONPICKLE_ABI, library);
    contractInstance.price().then(data => {
      setCurrentPrice((prev) => {
        if (prev > ethers.utils.formatEther(data)) {
          setIsDecresing(true);
        }
        if (prev < ethers.utils.formatEther(data)) {
          setIsDecresing(false);
        }
        if (prev == ethers.utils.formatEther(data)) {
          setIsDecresing(null);
        }
       return ethers.utils.formatEther(data)*1.05
      })
    })
    .catch(e => setCurrentPrice(0.0125*1.05))
  }

  const getRemaining = () => {
    const contractInstance = new Contract(PICKLE_AUCTION_CONTRACT, AUCTIONPICKLE_ABI, library);
    contractInstance.remaining().then(data => {
      setRemaining(Number(data))
    }).catch(e =>(111, e))
  }

  useEffect(() => {
    let interval;
    const lastViewDate = localStorage.getItem('lastViewDate');
    if (!lastViewDate) {
      setShowModal(true);
      localStorage.setItem('lastViewDate', JSON.stringify(new Date()));
    } else {
      const prevDate = new Date(JSON.parse(lastViewDate));
      const now = new Date();
      const diff = (now.getTime() - prevDate.getTime())/ 1000;
      if (diff > 2*60*60) {
        setShowModal(true);
        localStorage.setItem('lastViewDate', JSON.stringify(new Date()));
      }
    }
    getPrice();
    interval = setInterval(() => getPrice(), 10000);
    getRemaining();
    return () => clearInterval(interval);
  },[account, library])

  useEffect(() => {
    getEvents().then(events => {
      const tmp = decodeEvents(events.reverse());
      setEvents(tmp);
    });
    getSubmissionSettings().then((data) => setEnabled(data.publicSales));
  },[])

  return (
    <div style={{
      display: "flex",
      justifyContent:"space-between",
      height: "calc(100vh - 150px)",
      paddingBottom: '20px',
      flexWrap:"wrap"
    }}>
      {showModal && <MintInfo showModal={showModal} setShowModal={setShowModal}/>}
      <div style={{width: "100%",display: "flex", flexWrap:"wrap", justifyContent: "center", alignItems: "center"}}>
      <div className="drawWrapper hidesmall rulesText" style={{paddingInline: "40px", opacity: 0, height: "10px"}}>
      <div style={{borderBottom: "1px solid"}} className="text-secondary">History</div>
      </div>
    <div className="text-secondary" style={{
      fontSize: "20px",
      display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
      position: "relative",
      top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
    }}>
      <div className="drawWrapper" style={{background: "transparent"}}>
      {remaining > 0 && <p><b>Mint more(up to {remaining}) pickles!</b></p>}
      {(remaining === 0 && account) && <p>More pickles to come. Stay in touch!</p>}
      {!account && <p>Please connect your wallet!</p>}
      <img src={PickleImage} alt="pickle" style={{width: "100%"}}/>
      <div className="text-secondary" style={{
        display: "flex", gap: "40px",
        alignItems: "center"
        }}>
          {currentPrice > 0 && (
            <>
                    <div>Current Price</div>
        <div><b>{currentPrice.toFixed(5).toString().substring(1)}</b> ETH{isDecresing ? <>⬇️</> : isDecresing === false ? <>⬆️</> : null}</div>
            </>
          )}
      </div>
      {account && <button disabled={!enabled} onClick={handleMint} className="text-secondary submitDraw" style={{width: "200px", opacity: enabled ? 1 : 0.3}}>
       Mint
      </button>}
      {!account && <p>Connect your wallet</p>}
      <div onClick={() => setShowModal(true)} style={{
        borderBottom: "1px solid",
        marginTop:" 10px",
        marginBottom: "40px",
        fontSize: "16px",
        cursor:"pointer",
      }}>Why is price changing?</div>
      </div>
      <div className="drawWrapper showMobile" style={{paddingInline: "10px"}}>
      <div className="text-secondary" style={{textAlign:"center", marginBottom: "10px"}}><u>History</u></div>
      {events.slice(0,12).map((item,idx) => (
      <div key={idx} className="text-secondary" style={{opacity: 0.8,width: "100%",display: "flex", justifyContent:"space-between"}}>
        <HistoryRow item={item} />
      </div>
      ))}
      </div>
    </div>
    <div className="drawWrapper hideMobile" style={{paddingInline: "40px"}}>
      <div style={{borderBottom: "1px solid"}} className="text-secondary">History</div>
      {events.slice(0,12).map((item,idx) => (
      <div key={idx} className="text-secondary" style={{opacity: 0.8,width: "100%",display: "flex", justifyContent:"space-between"}}>
        <HistoryRow item={item} />
      </div>
      ))}
      </div>
    </div>
    <div style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "450px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "auto",
        marginTop: "60px",
        paddingInline: "10px",
      }}>
      <div><a href="https://opensea.io/collection/pickle-people" target="_new">
        <OpenseaIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
        <TwitterIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://discord.com/invite/jUBCf6pMZX">
        <DiscordIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://mustardlabs.io">
        <MustardIcon className="text-secondary"/>
        </a></div>
      </div>
      </div>
  )
}

const HistoryRow = ({item}) => {
  const { ens } = useLookupAddress(item.wallet);
  const [dateText, setDateText] = useState();
  const {library} = useEthers();

  useEffect(() => {
    library?.getBlock(item.blockNumber).then((block) => {
      if (block?.timestamp) {
        setDateText(
          formatDistance(new Date(block?.timestamp * 1000), new Date(), {
            addSuffix: true,
          })
        );
      }
    });

  })
  if (!item?.price || !item?.wallet) {return null}
  return (
    <>
    <div style={{display:"flex"}}><div style={{marginRight:"10px"}}><span style={{marginRight: "10px"}}>{dateText}</span>{Number(ethers.utils.formatEther(item.price.toString())).toFixed(5).substring(1)} ETH</div></div><div>{ens || sort0xAddress(item.wallet)}</div>
    </>
  )
}

export default PublicSale;
