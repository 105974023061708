import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/discord.svg';
import { ReactComponent as MustardIcon } from '../assets/mustard.svg';
import { ReactComponent as OpenseaIcon } from '../assets/opensea.svg';
import DancingPickleGif from '../assets/dancing_pickle.gif';
import SambaPickleGif from '../assets/samba_pickle.gif';
import { useEffect } from 'react';
import { useState } from 'react';
import { getFiveSubmissions } from '../firestore/firestore';
import { sort0xAddress } from '../components/ConnectWallet';
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import TraitNameModal from '../components/TraitNameModal';

const Info = ({picklesSubmitted, isArtist}) => {
  const [examples, setExamples] = useState([]);
  const [activeExample, setActiveExample] = useState(0);
  const [showTraitModal, setShowTraitModal] = useState();
  useEffect(() => {
    getFiveSubmissions().then((data) => setExamples(data))
  },[])
  return (
    <div style={{
      maxWidth: "1012px",
      paddingInline: "10px",
      marginInline: "auto",
      paddingTop: "30px"
    }}>
      {showTraitModal && <TraitNameModal setShowTraitModal={setShowTraitModal}  isArtist={isArtist}/>}
              <div className="mobileColumns" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingInline: "10px",
          background: "rgba(0,0,0,0.3)",
          width: "100%",
          borderRadius: "20px",
          marginBottom: "32px",
          padding: "14px 21px"
        }}>
          <img src={DancingPickleGif} alt="dancing pickle" style={{width: "91px"}} />
          <div style={{color: "#3C903A", fontSize: "24px"}}>Draw up to 4 pickles, then mint someone else's pickles, for free.</div>
          <img src={SambaPickleGif} alt="dancing pickle" style={{width: "91px"}} />
        </div>
      <div className="mediumFlexWrap" style={{
        display: 'flex',
        gap: "20px"
      }}>
        <div>
    {examples.length > 0 && (<div className="drawWrapper rulesText infoPage" style={{ marginBottom: "20px",maxWidth: "602px", marginInline: "auto", textAlign: "left"}}>
    <h2  className="" style={{
            fontFamily:"PixelFont",
            fontWeight: 400,
            fontSize: "20px",
            margin: 0
      }}>Some bangers</h2>
      <div style={{
        position:"relative",
      }}><img src={examples[activeExample].image} alt="example pickle" style={{width: "100%"}}/>
      <div style={{
        position: "absolute",
      top: '50%',
      transform: "translateY(-50%)",
      cursor: "pointer",
      }} onClick={() => setActiveExample(prev => {if (prev === 0) {return examples.length-1;} return prev - 1})}><ArrowIcon/></div>
            <div   style={{
        position: "absolute",
      top: '50%',
      cursor: "pointer",
      transform: "translateY(-50%) rotate(180deg)",
      right: 0,
      }} onClick={() => setActiveExample(prev => {if (prev === examples.length-1) {return 0;} return prev + 1})}><ArrowIcon/></div>
      </div>
      {examples[activeExample].name &&<p style={{fontSize: "20px", fontWeight: "700", marginBottom:0}}>{examples[activeExample].name}</p>}
      <p style={{marginTop: 0, marginBottom:0, fontSize: "16px"}}>by: {examples[activeExample].ens || sort0xAddress(examples[activeExample].wallet)}</p>
    </div>)}
        </div>
      <div>
      <div className="drawWrapper rulesText infoPage" style={{marginBottom: "20px",maxWidth: "602px", marginInline: "auto", textAlign: "left"}}>
    <h2  className="" style={{
            fontFamily:"PixelFont",
            fontWeight: 400,
            fontSize: "20px",
            margin: 0
      }}>Drawing period</h2>
      <ul style={{paddingLeft: "15px"}}>
        <li style={{ marginBottom: "25px", fontSize: "18px"}}>
        You can invite friends to draw using the invite button on the top right, next to your wallet.
        </li>
        <li style={{ marginBottom: "25px", fontSize: "18px"}}>There are no gas costs, but you will need to sign with your wallet.</li>
        <li style={{ marginBottom: "25px", fontSize: "18px"}}>Your wallet address or ENS will be added as a trait automatically. <br />(apply to add another name as a trait <div style={{fontWeight: 700, color: "#40b03d",textDecoration: "underline", cursor: "pointer", display: "inline-block"}} onClick={()=> setShowTraitModal(true)}>here</div>)</li>
        <li style={{ marginBottom: "25px", fontSize: "18px"}}>Low-effort / offensive pickles will be rejected and impact your mint limit.</li>
        <li style={{  fontSize: "18px"}}>Drawing period will end at our discretion. </li>
      </ul>
    </div>
    </div>
    </div>
    <div style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "450px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginInline: "auto",
            marginTop: "20px",
            marginBottom: "20px",
            paddingInline: "10px",
          }}>
      <div><a href="https://opensea.io/collection/pickle-people" target="_new">
        <OpenseaIcon className="text-secondary"/>
        </a></div>
            <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
            <TwitterIcon className="text-secondary"/>
            </a></div>
            <div><a href="https://discord.com/invite/jUBCf6pMZX">
            <DiscordIcon className="text-secondary"/>
            </a></div>
            <div><a href="https://mustardlabs.io/">
            <MustardIcon className="text-secondary"/>
            </a></div>
          </div>
    </div>
  );
};

export default Info;
