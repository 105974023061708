import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { addSubmission } from "../firestore/firestore";
import { randomBGs } from "../helpers/backgrounds";

const ShadowCopy = ({randomBGNumber, randomPickleNumber, account, ens, name, isUploading, setIsUploading, setDrawnImageUrl}) => {
  const [bgFrames, setBgFrames] = useState([]);
  const [isGif, setIsGif] = useState(false);
  const [producedFrames, setProducedFrames] = useState([]);
  const itemsRef = useRef([]);
  const [pickleImg, setPickleImg] = useState();
  useEffect(() => {
    const randomBG = `img/bg/${randomBGs[randomBGNumber]}`
    const randomPickle = `img/pickles/_${randomPickleNumber}.png`
    if (randomBG.indexOf("gif.gif") > -1) {
      setBgFrames([randomBG.replace("gif.gif", "gif-1.png"),randomBG.replace("gif.gif", "gif-2.png"),randomBG.replace("gif.gif", "gif-3.png"),randomBG.replace("gif.gif", "gif-4.png")]);
      setIsGif(true);
    } else {
      setBgFrames([randomBG]);
      setIsGif(false);
    }

    setPickleImg(randomPickle);
  }, [])

  const transfer = () => {
    const drawing = document.getElementById('pixels');
    bgFrames.forEach((frame,index) => {
      const clone = drawing.cloneNode(true);
      document.getElementById(`testing${index}`).appendChild(clone);
    })
  }

  useEffect(() => {
    if (bgFrames.length> 0) {
      transfer();
      generateGif();
    }
  },[bgFrames]) 

  const generateGif = async () => {
    const length = itemsRef.current.length;
    for (let i = 0; i < length; i++) {
      await html2canvas(itemsRef.current[i]).then(canvas => {
        const ctx = canvas.getContext('2d');
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
        const dataUrl = canvas.toDataURL('image/png', 1);
          setProducedFrames((prev) => [...prev, dataUrl]);

        //setDrawBlob(dataUrl);
      //   canvas.toBlob((blob) => {
      //     addSubmission({
      //       blob,
      //       wallet: account,
      //       name
      //     })
      //  }, 'image/png', 1);
      })
    }



  }
  useEffect(() => {
    if( producedFrames && producedFrames.length === bgFrames.length) {
      navigator.getUserMedia = {};
      window.gifshot.createGIF({
        interval: 0.2,
        gifWidth: 384,
        gifHeight: 384,
        keepCameraOn: false,
        video: null,
        cameraStream: null,
        webcamVideoElement: null,
        'images': [...producedFrames]
      },async function(obj) {
        if(!obj.error) {
          var image = obj.image;
          const blob = await (await fetch(image)).blob();
          const imageUrl = await addSubmission({
            blob,
            wallet: account,
            ens: ens ||null,
            name,
            pickleId: randomPickleNumber,
            bgId: randomBGNumber,
            bgFilename: randomBGs[randomBGNumber],
            gif:isGif
          });
          setDrawnImageUrl(imageUrl);
          setIsUploading(false);
        }
      });
    }
  }, [producedFrames])

return (
  <div style={{
    position: "fixed",
    top: '0',
    right: '-500px'
  }}>
  {bgFrames.map((frame,index) => (
    <div className="gifFrames" id={"gifFrame"+index} key={index} ref={
      el => itemsRef.current[index] = el
    }>
      <img src={frame} alt={"gif frame"+index} style={{zIndex: 1}}/>
      <img src={pickleImg} alt="pickle" style={{zIndex:2}} />
      <div id={"testing"+index} style={{zIndex:3}} />
    </div>
  ))}
  <img id="producedGif" style={{width:"384px", height: "384px"}}/>
  </div>
)
}
export default ShadowCopy;