import { useEthers } from "@usedapp/core"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sort0xAddress } from "../components/ConnectWallet";
import { createJudge, getJudgeStats, getSubmissionsToJudge, getVotes, increaseJudgesVotesCounter, updateSubmissionsAvgVote } from "../firestore/firestore";

const Judge = ({judges}) => {
  const [submissions, setSubmissions] = useState(null)
  const [vote, setVote] = useState(undefined);
  const [votes, setVotes] = useState([]);
  const [nJudges, setNJudges] = useState(0);
  const { account } = useEthers();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (account) {
      getJudgeStats(account).then((votes) => setNJudges(votes));
    }
  },[account])

  useEffect(() => {
    if (!account) return;
    getVotes(id)
    .then((data) => {
      setVotes(data)
      console.log(account, data);
      if (data && account) {
        const myVote = data.find(item => item.judge === account.toLowerCase());
        console.log(myVote)
        if (myVote) {
          setVote(myVote.vote)
        }
      }
    })
    getSubmissionsToJudge(id, account)
    .then((data) => setSubmissions(data));
  },[id, account])

  const handleNext = () => {
    if (vote === undefined) {
      alert("You MUST vote.");
      return;
    }
    createJudge(submissions[0], vote, account).then(() => {
      increaseJudgesVotesCounter(account);
      setNJudges((prev) => prev+1)
      navigate('/judge/'+submissions[1].assignedNumber);
    })
    .catch(() => alert("There was an error please contact immunity"))

  }

  useEffect(() => {
    setVote(undefined);
  },[id])

  console.log(votes);
  const getAvg = () => {
    let avg = 0;
    let counter = 0;
    votes.forEach((item) => {counter += 1; avg += item.vote});
    const myVote = votes.find(item => item.judge === account.toLowerCase());
    if (!myVote) {
      counter += 1;
      avg += vote;
    }
    return {avg: avg/counter, count: counter};
  }

  if (!account || !judges.includes(account.toLowerCase()) || !submissions) {
    return null;
  }

  return (
    <div style={{
      position:"absolute",
      top:0,
      left:0,
      bottom:0,
      right:0,
      paddingTop: "150px",
      zIndex:-1
    }}>
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent:"space-between",
      height: "calc(100vh - 150px)",
      paddingBottom: '20px',
      position:"relative"
    }}>
    <div className="text-secondary" style={{
      fontSize: "20px",
      display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
      position: "relative",
      top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
    }}>
<div>
<p style={{textAlign: "center"}}><strong>{submissions[0].name}</strong></p>
<p style={{color: "white", textAlign: "center"}}>by: {submissions[0].ens || sort0xAddress(submissions[0].wallet)}</p>
{(votes?.length > 0 && vote !== undefined) && <p style={{textAlign: "center"}}>Votes: {getAvg().count} of avg: {getAvg().avg}<br />(Real-time avg but only for this page not for avg-judged/X page)</p>}
{nJudges > 0 && <p style={{textAlign: "center"}}>Good job! you have review {nJudges} drawing of 3894</p>}
</div>
      <img src={submissions[0].image} alt="waiting pickle" style={{maxWidth: "384px", width: '100%'}}/>
    <div style={{
      display: "flex",
      gap: "20px",
      flexWrap: "wrap"
    }}>
      <button style={{width: "auto", paddingInline: "20px", border: vote === 0 ? '3px solid' : '1px solid', opacity: vote === 0 ? 1 : 0.4}} className="text-secondary submitDraw" onClick={() => setVote(0)}>0</button>
      <button style={{width: "auto",paddingInline: "20px", border: vote === 1 ? '3px solid' : '1px solid', opacity: vote === 1 ? 1 : 0.4}} className="text-secondary submitDraw" onClick={() => setVote(1)}>1</button>
      <button style={{width: "auto",paddingInline: "20px", border: vote === 2 ? '3px solid' : '1px solid', opacity: vote === 2 ? 1 : 0.4}} className="text-secondary submitDraw" onClick={() => setVote(2)}>2</button>
      <button style={{width: "auto",paddingInline: "20px", border: vote === 3 ? '3px solid' : '1px solid', opacity: vote === 3 ? 1 : 0.4}} className="text-secondary submitDraw" onClick={() => setVote(3)}>3</button>
      <button style={{width: "auto",paddingInline: "20px", border: vote === 4 ? '3px solid' : '1px solid', opacity: vote === 4 ? 1 : 0.4}} className="text-secondary submitDraw" onClick={() => setVote(4)}>4</button>
      <button style={{width: "auto",paddingInline: "20px", border: vote === null ? '3px solid' : '1px solid', opacity: vote === null ? 1 : 0.4}} className="text-secondary submitDraw" onClick={() => setVote(null)}>Maybe</button>
    </div>
    <div style={{
      display: "flex",
      gap: "20px"
    }}>
      <button onClick={()=> handleNext()} style={{paddingInline: "20px"}} className="text-secondary submitDraw">Next</button>
      <button onClick={() => updateSubmissionsAvgVote(submissions[0].id)}>Update Average score on avg-judged page</button>
    </div>
    </div>
      </div>
      </div>
  )
};

export default Judge;
