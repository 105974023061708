import { useEthers } from "@usedapp/core";
import React, { useEffect, useState } from "react";
import "../../styles/pixel.css";

export default function Pixel(props) {
  const { account } = useEthers();
  const { saveToHistory, selectedColor, steadyHand = true, drawHistory, setDrawHistory, index, rowIndex, randomBGNumber, randomPickleNumber } = props;
  const [pixelColor, setPixelColor] = useState("rgba(255,255,255,0)");
  const [oldColor, setOldColor] = useState(pixelColor);
  const [canChangeColor, setCanChangeColor] = useState(true);
  
  useEffect(() => {
    const memory = localStorage.getItem("pickleDrawHistory");
    if (memory && saveToHistory) {
      const obj2 = JSON.parse(memory);
      if (obj2 && obj2.length > 0) {
        const obj = obj2.reverse();
        if (obj[0].randomBGNumber === randomBGNumber && obj[0].randomPickleNumber === randomPickleNumber) {
          const pixelInHistory = obj.find((it) => it.row === rowIndex && it.pixel === index);
          if (pixelInHistory) {
            setPixelColor(pixelInHistory.color);
          } else {
            setPixelColor("transparent");
          }
        } else {
          localStorage.setItem("pickleDrawHistory", JSON.stringify([]));
        }
      }
    }
  },[randomPickleNumber, saveToHistory]);

  function applyColor() {
    setPixelColor(selectedColor);
    setCanChangeColor(false);
    if (account && saveToHistory) {
      setDrawHistory((prev) => [...prev, {randomBGNumber, randomPickleNumber, row: rowIndex, pixel: index, color: selectedColor, }]);
    }
  }

  function detectLeftButton(evt) {
    evt = evt || window.event;
    if ("buttons" in evt) {
        return evt.buttons == 1;
    }
    var button = evt.which || evt.button;
    return button == 1;
}

  function changeColorOnHover(e) {
    if (steadyHand && detectLeftButton(e)) {
      if (account && saveToHistory) {
        setDrawHistory((prev) => [...prev, {randomBGNumber, randomPickleNumber, row: rowIndex, pixel: index, color: selectedColor, }]);
      }
      setPixelColor(selectedColor);
    } else {
      setOldColor(pixelColor);
      setPixelColor(selectedColor);
    }
  }

  function resetColor(e) {
    if (steadyHand && detectLeftButton(e)) {
      setCanChangeColor(true);
    } else {
      if (canChangeColor) {
        setPixelColor(oldColor);
      }
      setCanChangeColor(true);
    }
  }

  return (
    <div
      className="pixel"
      onMouseDown={applyColor}
      onMouseUp={applyColor}
      onMouseOverCapture={changeColorOnHover}
      onMouseLeave={resetColor}
      style={{ backgroundColor: pixelColor }}
    ></div>
  );
}
