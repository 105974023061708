import { useEthers, useResolveName } from "@usedapp/core"
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { createInvite, getArtistWhitelists, getMyInvites, getUnlimitedPass, isWalletAlreadyAllowed } from "../firestore/firestore";

const InvitationModal = ({setShowInvitationModal}) => {
  const [inviteWallet, setInviteWallet] = useState('');
  const [invited, setInvited] = useState(false);

  useEffect(() => {
    if (invited) {
      setTimeout(() => window.location.reload(), 2000)
    }
  }, [invited]);

  return (
    <div 
    onClick={()=> {invited ? window.location.reload() :setShowInvitationModal(false)}}
    style={{
      position: "fixed",
      zIndex:4000,
      top:0,
      bottom:0,
      left:0,
      right:0,
      background: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(2px)",
    }}>
      <div
      onClick={(e) => e.stopPropagation()}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#102F17",
        padding: "15px",
        width: "100%",
        maxWidth: "346px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        borderRadius: "10px",
        justifyContent: "start",
        alignItems: "start"
      }}>
        {!invited && (
          <>

        <h2 className="text-secondary" style={{margin: 0, fontFamily: "PixelFont", fontSize: "14px", textAlign:"center", width: "100%"}}>Invite Friends</h2>
        <p className="text-secondary"  style={{margin: 0, fontSize: "10px"}}>Enter wallet address or ENS below</p>
        <input placeholder="Wallet Address" type="text" value={inviteWallet} onChange={(e) => setInviteWallet(e.target.value)}  style={{
          background: "rgba(0,0,0,0.5)",
          color: "white",
          textAlign: "left",
          border: "none",
          borderRadius: "20px",
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          fontSize: "10px"
        }}/>
        <InviteButton setInvited={setInvited} wallet={inviteWallet} setShowInvitationModal={setShowInvitationModal}/>
        </>
        )}
        {invited && <h2 className="text-secondary" style={{marginInline: "auto",fontFamily: "PixelFont", fontSize: "14px", textAlign: "center"}}>Invite sent!</h2>}
      </div>
    </div>
  )
}

const InviteButton = ({ wallet, setShowInvitationModal, setInvited }) => {
  const { account, library } = useEthers();
  const [isAlreadyInvited, setIsAlreadyInvited] = useState();
  const [loading, setLoading] = useState(false);
  const handleInvite = async () => {
    console.log({loading})
    if (loading) return;
    setLoading(true);
    setIsAlreadyInvited(false);
    let checkingAddress = wallet.toLowerCase();
    if (!ethers.utils.isAddress(wallet)) {
      console.log('inside ens')
      const resolved = await library.resolveName(wallet).then((res) => res).catch(e => alert("Wrong wallet address"));
      if (!resolved) {
        setLoading(false);
        alert("Wrong wallet address")
        return;
      }

      checkingAddress= resolved.toLowerCase();
      console.log({checkingAddress})
    }
    const isAlreadyAllowed = await isWalletAlreadyAllowed(checkingAddress);
    if (isAlreadyAllowed) {
      setIsAlreadyInvited(true);
      setLoading(false);
      console.log({isAlreadyAllowed})
      return;
    }
    const invites = await getMyInvites(account.toLowerCase());
    const isArtist = await getArtistWhitelists(account.toLowerCase());
    const isUltimatePass = await getUnlimitedPass(account.toLowerCase());
    console.log(invites, isArtist, isUltimatePass)
    
    if ((!isUltimatePass &&!isArtist && 1 < invites.length) || (!isUltimatePass && isArtist && 19 < invites.length)) {
      alert("You have no invites");
      setLoading(false);
      return;
    }
    console.log('before invitation')
    const created = createInvite(checkingAddress.toLowerCase(), account.toLowerCase())
    console.log('after invitation)')
    if (created) {
      setInvited(true);
    } else {
      setShowInvitationModal(false);alert("There was an error!");setLoading(false);
    }
  }

  return (
    <>
    {isAlreadyInvited && <p style={{margin:0, color: "white", fontSize: "12px", marginBop: "5px"}}>That wallet is already on the allowlist</p>}
    <button className="text-secondary submitDraw" style={{margin: 0, display: "flex", gap: "10px", marginInline: "auto", justifyContent: "center"}} onClick={() => handleInvite()}>{loading && <div className="spinner">C</div>}INVITE</button>

    </>
  )
}

export default InvitationModal;
