import { useEffect, useRef, useState } from "react";
import DrawingPanel from "../components/PixelDrawer/DrawingCanvas";
import { CirclePicker } from 'react-color';
import { ReactComponent as PenIcon } from '../assets/penIcon.svg';
import { ReactComponent as EraserIcon } from '../assets/eraserIcon.svg';
import { ReactComponent as MoreIcon } from '../assets/moreIcon.svg';
import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/discord.svg';
import { ReactComponent as MustardIcon } from '../assets/mustard.svg';
import { usePopper } from "react-popper";
import ShadowCopy from "../components/ShadowCopy";
import { useEthers, useLookupAddress } from "@usedapp/core";
import { randomBGs } from "../helpers/backgrounds";
import { checkActiveDrawing, createActiveDrawingDoc } from "../firestore/firestore";
import { Link } from "react-router-dom";
import DancingPickleGif from '../assets/dancing_pickle.gif';
import TraitNameModal from "../components/TraitNameModal";


const availableColors= [
"#FFB3B3", "#FFD2B3","#FFE0B2","#FFF1B4","#F4FFB3","#B3FFB4","#B3FFF2","#B4D0FF","#B7B3FF","#D5B3FF","#FEB3EC" ,"#E5B071", "#6B9B2C","#57A52E","#696969","#FFFFFF",
"#FF6766","#FFA366","#FEC166","#FEEC66","#E8FF66","#70F870","#66FEE5","#66A1FF","#6D67FF","#AF68FF","#FF66DB","#AE814C", "#537C1A","#407D20","#3E3E3E", "#E6E6E6",
"#C50E0F","#BC520B","#CC7900","#E8CB02","#BCDB02","#13C712","#00CCA9","#004ECC","#2822BB","#5D00CB","#BF1A99","#644725","#41601B","#275F1B","#191919", "#C1C1C1",
"#5E0707","#53280B","#66410B","#645A07","#556307","#006600","#086152","#082655","#100C5C","#320962","#66004E","#402B12", "#283912","#1D4614","#000000","#969696",
];


const tmpRandomBGNumber = Math.floor(Math.random()*randomBGs.length);
const tmpRandomPickleNumber = Math.floor(Math.random()*19999+1);

const Drawing = ({ picklesSubmitted, setPicklesSubmitted, setIsArtist, isArtist, isAllowed, setIsAllowed, judges}) => {
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadReady, setIsUploadReady] = useState(false);
  const [drawnImageUrl, setDrawnImageUrl] = useState();
  const { account } = useEthers();
  const { ens } = useLookupAddress(account);
  const panelRef = useRef();
  const [drawBlob, setDrawBlob] = useState(null);
  const [clickAndDrag, setClickAndDrag] = useState(true);
  const [showPopper, setShowPopper] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [name, setName] = useState("");
  const [randomBGNumber, setRandomBGNumber] = useState(undefined);
  const [randomPickleNumber, setRandomPickleNumber] = useState(undefined);

  useEffect(() => {
    if (isUploading) {
      setIsUploadReady(true)
    }
  }, [isUploading])

  const fetchInitialImages = async () => {
    const activeDrawing = await checkActiveDrawing(account);
    if (activeDrawing && !activeDrawing.submitted) {
      setRandomBGNumber(activeDrawing.bgId);
      setRandomPickleNumber(activeDrawing.pickleId);
    } else {
      setRandomBGNumber(tmpRandomBGNumber);
      setRandomPickleNumber(tmpRandomPickleNumber);
      createActiveDrawingDoc(account,tmpRandomPickleNumber ,tmpRandomBGNumber)
    }
  }

  const changeColor = (color) =>{
    setSelectedColor(color.hex);
  }

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
  })

  const [resetDrawer,setResetDrawer] = useState(false);
  const [saveToHistory, setSaveToHistory] = useState(false);
  useEffect(() => {
    if (resetDrawer) {
      setResetDrawer(!resetDrawer);
    }
  }, [resetDrawer])

  useEffect(() => {
    if (account) {
      fetchInitialImages();
    }
  },[account])

  const [showTraitModal, setShowTraitModal] = useState(false);

  if (!account || !judges.includes(account.toLowerCase())) {
    return null;
  }

  return (
    <div>
{showPopper && (
        <div
        ref={setPopperElement}
        style={{...styles.popper, backdropFilter:"blur(5px)", background: 'rgba(0,0,0,0.6)', color: "white", padding: "8px 10px", borderRadius: '10px', marginInline: '20px', marginTop: "25px"}}
        {...attributes.popper}
        >
          <label htmlFor="showgrid" style={{display: "block"}}>
            <input id="showgrid" type="checkbox" value={showGrid} checked={showGrid} onClick={() => setShowGrid(!showGrid)}/> Show Grid
          </label>
          <label htmlFor="clickAndDrag" style={{display: "block", marginTop: "10px"}}>
            <input id="clickAndDrag" type="checkbox" value={clickAndDrag} checked={clickAndDrag} onClick={() => setClickAndDrag(!clickAndDrag)}/> Click and drag
          </label>
          <button onClick={() => {setResetDrawer(true);localStorage.setItem("pickleDrawHistory", "[]")}} style={{padding:0, background:0, border: "none", color: "white",marginLeft: "2px", marginTop: "10px", fontSize: "16px"}}>Start Over</button>
        </div>
)}
<div className="showMobile" style={{marginInline: "auto", maxWidth:"320px", width: "100%", textAlign:"center"}}>
  <img src={DancingPickleGif} style={{maxWidth:"320px", width: "100%", marginTop: "-50px"}} alt="dancing pickle"/>
  <p className="text-secondary" style={{fontSize: "18px"}}>Pickle drawing is only</p>
  <p className="text-secondary" style={{fontSize: "18px", marginBottom: "40px"}}>supported on desktop.</p>

  <Link className="text-secondary" style={{fontFamily: "PixelFont", textDecoration: "none"}} to="/info">INFO</Link>
  <div style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "450px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "auto",
        marginTop: "30px"
      }}>
        <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
        <TwitterIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://discord.com/invite/jUBCf6pMZX">
        <DiscordIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://mustardlabs.io">
        <MustardIcon className="text-secondary"/>
        </a></div>
      </div>
</div>
      <div className="hideMobile"style={{display: "flex", justifyContent: "center", marginBottom: "16px", flexWrap: "wrap", gap: "20px"}}>
      <div style={{ flexGrow:1}} className="rulesText hidesmall">
        <h3>1. Draw Pickles</h3>
        <p>Bring pickles to life!</p>
        <p>Find out more about submission rules <Link to="/info">here</Link></p>
        <p>Low effort and offensive submissions will be rejected.</p>
        <div style={{display: "flex", flexWrap:"wrap", alignItems: "center"}}><h3>2. Sign Your Pickles</h3><span>(optional)</span></div>
        <p>Add your name to your pickles metadata as an official trait!</p>
        <p>Wallet / ENS added automatically.</p>
        <p>Apply <a style={{fontWeight: 700, color: "#40b03d"}} target="_new" href="https://docs.google.com/forms/d/e/1FAIpQLSfAB-fudIh2fz6oFv7sHx-xu0Zg6c2lYzOAmkSTkgytqNigIA/viewform">here</a> to add a custom name instead.</p>
        <h3>3. Mint Pickles</h3>
        <p>Free mint for pickle contributors.</p>
        <p>Mintable for non contributors at public sale.</p>
        <p>Mint date TBD<br/>
        Public sale price TBD</p>
        <p>Click <Link to="/info">here</Link> for more information about the project</p>
      </div>
        <div style={{width: "100%", maxWidth: "450px", flexGrow:1}}>
      <div className={`drawWrapper${showGrid ? " showGrid": ""}`} style={{marginInline: "auto", marginBottom: "16px", backdropFilter:"blur(4px)",}}>
        <div className="drawTools">
        <button style={{marginBottom: "20px"}} type="button" ref={setReferenceElement} onClick={() => setShowPopper(!showPopper)}>
          <MoreIcon style={{fill: showPopper ? "white" : ""}}/>
        </button>
        <button onClick={() => setSelectedColor("#FFFFFF")} className={selectedColor !== 'rgba(255,255,254,0)'? "active": ""}>
          <PenIcon />
        </button>
        <button className={selectedColor === 'rgba(255,255,254,0)'? "active": ""} onClick={() => setSelectedColor("rgba(255,255,254,0)")}>
          <EraserIcon />
        </button>
        </div>
      <div className="text-secondary" style={{
        fontFamily: "PixelFont",
        fontSize: "12px",
        opacity: account && isArtist ? 1 : 0
      }}>Draw your pickle</div>
      <div style={{position: "relative"}}>
        <div style={{
          borderRight: "1px solid white",
          position: "absolute",
          right: '103px',
           height: "60px"
        }}></div>
      <CirclePicker color={selectedColor} onChangeComplete={changeColor} colors={availableColors}/>
      </div>
      {!resetDrawer && (
        <DrawingPanel
        setSaveToHistory={setSaveToHistory}
        saveToHistory={saveToHistory}
        setIsArtist={setIsArtist}
        setIsUploading={setIsUploading}
        isUploading={isUploading}
        ref={panelRef}
        setDrawBlob={setDrawBlob}
        randomBGNumber={randomBGNumber}
        randomPickleNumber={randomPickleNumber}
        width={48} height={48}
        selectedColor={selectedColor}
        clickAndDrag={clickAndDrag}
        isAllowed={isAllowed}
        setIsAllowed={setIsAllowed}
        name={name}
        setShowGrid={setShowGrid}
        setName={setName}
        />
      )}
      {resetDrawer && <div style={{width: "100%", maxWidth: "336px", height: "100%", maxHeight: "443px", aspectRatio: "0.3", visibility: "hidden"}}>dsad saads asd sads adsad sadsadsadsadsad sadsad sadasd sad sasad sad sadasd</div>}
      </div>
      {drawBlob && !resetDrawer && (
        <ShadowCopy setIsUploading={setIsUploading} isUploading={isUploading} ens={ens} account={account} name={name} panelRef={panelRef} drawBlob={drawBlob} randomBGNumber={randomBGNumber} randomPickleNumber={randomPickleNumber} setDrawnImageUrl={setDrawnImageUrl}/>
      )}
        
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "450px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "auto"
      }}>
<div style={{visibility: picklesSubmitted != null ? "visible" : "hidden"}} className="text-secondary"><strong>{picklesSubmitted}</strong> pickles submitted</div>
        <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
        <TwitterIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://discord.com/invite/jUBCf6pMZX">
        <DiscordIcon className="text-secondary"/>
        </a></div>
        <div><a href="https://mustardlabs.io">
        <MustardIcon className="text-secondary"/>
        </a></div>
      </div>
      </div>
      {showTraitModal && <TraitNameModal setShowTraitModal={setShowTraitModal} isArtist={isArtist}/>}
      <div style={{ flexGrow:1, textAlign: "center"}} className="rulesText">
        <h3 style={{fontFamily: "PixelFont"}}>1. Draw Pickles</h3>
        <p style={{marginLeft: "20px", marginBottom: "30px"}}>Up to your drawing limit</p>
        <h3 style={{fontFamily: "PixelFont"}}>2. Sign + Submit</h3>
        <p style={{marginLeft: "20px", marginBottom: "30px"}}>Wallet / ENS added automatically as an official trait.<br /> Apply <div style={{fontWeight: 700, color: "#40b03d",textDecoration: "underline", cursor: "pointer" , display: "inline-block"}} onClick={()=> setShowTraitModal(true)}>here</div> to add a custom name instead.</p>
        <h3 style={{fontFamily: "PixelFont"}}>3. Free Mint</h3>
        <p style={{marginLeft: "20px", marginBottom: "30px"}}>Free mint for participants.<br /> Mint date TBD.</p>
        <p style={{marginLeft: "20px", marginBottom: "30px"}}>Low effort submissions will be rejected and impact your mint limit</p>
        <p>More details <Link to="/info" style={{fontWeight: 700, color: "#40b03d"}}>here</Link>.</p>
      </div>
      </div>
      {isUploading && (
        <div style={{
          background: "rgba(0,0,0,0.3)",
          position: "fixed",
          zIndex:200,
          top:0,
          left:0,
          right:0,
          bottom:0,
        }}>
      <div style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        padding: " 40px 20px",
        maxWidth: "100%",
        color: "white",
        boxShadow: "0 0 3px 2px rgba(0,0,0,0.2)",
        background: "#102F17",
      }}>
      Your submission is being uploaded. Please wait
    </div>
    </div>
      )}
      {!isUploading && drawnImageUrl && (
                <div style={{
                  background: "rgba(0,0,0,0.3)",
                  position: "fixed",
                  zIndex:200,
                  top:0,
                  left:0,
                  right:0,
                  bottom:0,
                }}>
        <div style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: " 40px 20px",
          width: "100%",
          maxWidth: "400px",
          background: "#102F17",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          boxShadow: "0 0 3px 2px rgba(0,0,0,0.2)"
        }}>
          <h2 style={{
            textAlign: "center",
            maxWidth: "360px",
            fontFamily:"PixelFont"
        }}
            className="text-secondary">Thank you<br /> for submitting...</h2>
            {name &&<p className="text-secondary" style={{
              margin:0,
              textAlign: "center",
              fontSize: "18px",
              fontWeight: 800
            }}>{name}</p>}
          <img src={drawnImageUrl} alt="drawn pickle" style={{ width: "100%"}}/>
          <a style={{textDecoration: "none", borderBottom: "2px solid", fontWeight: 700}} className="text-secondary" href={drawnImageUrl} download target="_new">Download</a>
          <p className="text-secondary" style={{
            textAlign: "center",
            maxWidth: "340px",
            fontSize: "14px",
            margin: 0
          }}>
            Share your pickle on twitter and tag us for the chance to increase your draw / mint limits.
          </p>
          <button className="submitDraw text-secondary" onClick={() => window.location.reload()}>Draw Another</button>
        </div>
        </div>
      )}
    </div>
  )
}

export default Drawing;