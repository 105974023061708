import BadgePng from '../assets/picklebadge.png';
import { ReactComponent as TwitterIcon } from '../assets/twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/discord.svg';
import { ReactComponent as MustardIcon } from '../assets/mustard.svg';
import { ReactComponent as OpenseaIcon } from '../assets/opensea.svg';
import { useEthers } from '@usedapp/core';
import { Contract, ethers } from 'ethers';
import { POAPPICKLE_ABI, POAPPICKLE_CONTRACT } from '../web3/constants';
import mekleJson from '../web3/merkle_tree.json';
import MerkleTree from 'merkletreejs';
import { keccak256 } from 'ethers/lib/utils';
import proofs from '../web3/all-proofs.json';

const hashAccount = (value) =>{
  return Buffer.from(
    ethers.utils
      .solidityKeccak256(["address"], [value[0]])
      .replace(/^0x/, ""),
    "hex"
  );
}

  const makeWhitelist = (leaves) =>
  new MerkleTree(leaves, keccak256, { sort: true });

const Claiming = ({picklesSubmitted}) => {
  const {account, library} = useEthers();

  const handleClaim = () => {
    if (!proofs[account.toLowerCase()]) {
      alert("You are not in whitelist.");
      return;
    }
    const whitelist = mekleJson;
    const hashedWhitelist = whitelist.map(hashAccount);
    const merkle = makeWhitelist(hashedWhitelist);
    const hashed = hashAccount([account.toLowerCase()]);
    const proof = merkle.getHexProof(hashed);
    const contractInstance = new Contract(POAPPICKLE_CONTRACT, POAPPICKLE_ABI, library.getSigner());
    contractInstance.mint(proofs[account.toLowerCase()], account).then(data => console.log(data));
  }
return (
  <div style={{
    display: "flex",
    flexDirection: "column",
    justifyContent:"space-between",
    height: "calc(100vh - 150px)",
    paddingBottom: '20px',
    
  }}>
  <div className="text-secondary" style={{
    fontSize: "20px",
    display: "flex", flexDirection: "column", gap: "50px", alignItems: "center",
    position: "relative",
    top: window.innerHeight >600? "50%" : "auto",transform:window.innerHeight >600? 'translateY(-50%)': "auto",
  }}>
    <img src={BadgePng} alt="waiting pickle" style={{maxWidth: "278px", width: '100%'}}/>
    <div>
    <p style={{
      color: "white",
      fontSize: "20px",
      fontWeight: "bolder",
      textAlign: "center"
    }}>
      L'Artiste - 2022
    </p>
    <p style={{
      width: "100%", maxWidth: "292px", color: "white", fontSize: "12px", textAlign: "center"
    }}>A free ERC1155 certification badge for anyone who participated in Pixelated Pickle People, 2022.</p>
    <div style={{
      textAlign: "center"
    }}><button style={{width: "auto", paddingInline: "40px"}} className="text-secondary submitDraw" onClick={handleClaim}>Claim</button></div>
    
    </div>

  </div>
  <div style={{
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "450px",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginInline: "auto",
      marginTop: "20px"
    }}>
            <div><a href="https://opensea.io/collection/pickle-people" target="_new">
        <OpenseaIcon className="text-secondary"/>
        </a></div>
      <div><a href="https://twitter.com/PicklePeopleNFT" target="_new">
      <TwitterIcon className="text-secondary"/>
      </a></div>
      <div><a href="https://discord.com/invite/jUBCf6pMZX">
      <DiscordIcon className="text-secondary"/>
      </a></div>
      <div><a href="https://mustardlabs.io">
      <MustardIcon className="text-secondary"/>
      </a></div>
    </div>
    </div>
)
}

export default Claiming;
