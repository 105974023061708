import DancePickle from '../assets/dancing_pickle.gif';
import { PICKLE_AUCTION_CONTRACT } from '../web3/constants';
const MintInfo = ({setShowModal}) => {

  return (
    <div 
    onClick={()=> setShowModal(false)}
    style={{
      position: "fixed",
      zIndex:4000,
      top:0,
      bottom:0,
      left:0,
      right:0,
      background: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(2px)",
    }}>
      <div
      className="text-secondary"
      onClick={(e) => e.stopPropagation()}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#102F17",
        padding: "30px",
        width: "100%",
        maxWidth: "646px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        borderRadius: "10px",
        justifyContent: "center",
        alignItems: "center",
        textAlign:"center"
      }}>
        <div style={{fontFamily: "PixelFont", textAlign:"center"}}>Public Sale Info</div>
        <p>This is an experimental mechanism similar to a dutch auction.</p>
        <p>Mint price goes up or down based on how quickly the previous NFT sells.</p>
        <p>The price of the current auction will continuously drop (by 20%) every 30 min, until the minimum price is met (.0125 ETH).</p>
        <div>
          <img src={DancePickle} alt="pickle dancing" style={{width: "100%", maxWidth: "90px"}}/><br />
          <a className="text-secondary" target="new" href={"https://www.etherscan.com/address/"+PICKLE_AUCTION_CONTRACT} style={{textDecoration: "underline"}}>View Contract</a>
          <button className="text-secondary submitDraw" onClick={() => setShowModal(false)}>I Understand</button>
        </div>
        </div>
    </div>
  )
}
export default MintInfo;
