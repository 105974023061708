import { initializeApp } from '@firebase/app';
import {
  getFirestore,
  getDocs,
  collection,
  where,
  query,
  limit,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  increment,
  getDoc,
  orderBy,
  addDoc,
  startAfter,
  startAt
} from '@firebase/firestore';
import { getAnalytics } from '@firebase/analytics';
import { getStorage, ref, uploadBytes } from '@firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA86bAaLMkoQXvx8MTn2Z63PN9VONv1Tpk",
  authDomain: "pickles-2d4f3.firebaseapp.com",
  projectId: "pickles-2d4f3",
  storageBucket: "pickles-2d4f3.appspot.com",
  messagingSenderId: "196266052292",
  appId: "1:196266052292:web:1d43feb149624842cb1791",
  measurementId: "G-4TWT8MLNLM"
};

const app = initializeApp(firebaseConfig, 'regular-companies');
const analytics = getAnalytics(app);
const db = getFirestore(app);

export const companiesDB = db;

export const isInvited = async (account) => {
  const invitationCollection = 'invitations';
  const invited = await getDoc(doc(db, invitationCollection,account.toLowerCase()))
  if (invited?.data()) {
    return invited.data();
  }
  return null;
}

export const getWhitelists = async (account) => {
  const pickleConnection = 'pickles_whitelists';
  const q = query(
    collection(db, pickleConnection),
    where('wallets',"array-contains", account.toLowerCase()),
    limit(1)
  );
  
  const querySnap = await getDocs(q);
  let data = null;
  querySnap.forEach(doc => {
    data = doc.data();
  });
  return data
};

export const getArtistWhitelists = async (account) => {
  const pickleConnection = 'pickles_whitelists';
  const q = query(
    collection(db, pickleConnection),
    where('artists',"array-contains", account.toLowerCase()),
    limit(1)
  );
  
  const querySnap = await getDocs(q);
  let data = null;
  querySnap.forEach(doc => {
    data = doc.data();
  });
  return data
};

export const getUnlimitedPass = async (account) => {
  const pickleConnection = 'pickles_whitelists';
  const q = query(
    collection(db, pickleConnection),
    where('unlimited',"array-contains", account.toLowerCase()),
    limit(1)
  );
  
  const querySnap = await getDocs(q);
  let data = null;
  querySnap.forEach(doc => {
    data = doc.data();
  });
  return data
};

export const getMySubmissions = async (wallet) => {
  const submissionCollection = 'submissions';
  const q = query(
    collection(db, submissionCollection),
    where('wallet', '==', wallet.toLowerCase())
  );

  const querySnap = await getDocs(q);
  let data = [];
  querySnap.forEach(doc => {
    data.push(doc.data());
  });
  return data
}

export const addSubmission = async (submission) => {
  const { wallet, name = null, blob, pickleId, bgId, gif = false, ens, bgFilename } = submission;
  const url = 'https://firebasestorage.googleapis.com/v0/b/pickles-2d4f3.appspot.com/o/';
  const newSubmissionRef = doc(collection(db, "submissions"));
  const uploaded = await uploadSubmission(blob, newSubmissionRef.id, gif)
  .then(data => data)
  .catch(e => alert(e));

  if (!uploaded) return null;

  try {
    const drawHistory = localStorage.getItem('pickleDrawHistory');
    const drawHistorySize = new Blob([drawHistory || " "]).size;
    const imgUrl = `${url}submissions%2F${newSubmissionRef.id}.${gif ? "gif": "png"}?alt=media`;
    await setDoc(newSubmissionRef, {
      id: newSubmissionRef.id,
      wallet:wallet.toLowerCase(),
      name: name,
      nameLowerCase: name.toLowerCase(),
      image: imgUrl,
      bgId,
      ens,
      pickleId,
      bgFilename,
      createdAt: serverTimestamp(),
      drawHistory: (drawHistory && drawHistorySize < 1048000) ? drawHistory : null,
    });
    localStorage.removeItem('pickleDrawHistory');
    createActiveDrawingDoc(wallet.toLowerCase(), pickleId,bgId, true);
    increaseSubmissionCounter(wallet.toLowerCase(), name, imgUrl);
    return imgUrl;
  } catch (e) {
    console.log(e)
    alert(e);
  }
  return null;
}

export const getSubmissionSettings = async () => {
  const docSnap = await getDoc(doc(db, "settings","submissions"));
  if (docSnap?.data()) {
    return docSnap.data();
  }
  return {
    counter: 0,
  };
}

const increaseSubmissionCounter = (wallet, name, url) => {
  updateDoc(doc(db, "settings","submissions"), {
    lastCreatedAt: serverTimestamp(),
    lastWallet:wallet.toLowerCase(),
    lastImageUrl: url,
    name: name || null,
    counter: increment(1)
  })
}

const uploadSubmission = async (file, filename, gif = false) => {
  const storage = getStorage(app);
  const metadata = {
    contentType: `image/${gif ? "gif": "png"}`,
  };
  const storageRef = ref(storage, `submissions/${filename}.${gif ? "gif": "png"}`);
  try {
    await uploadBytes(storageRef, file, metadata);
    return true;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export const checkNameDuplication = async (name) => {
  const submissionCollection = 'submissions';
  const q = query(
    collection(db, submissionCollection),
    where('nameLowerCase', '==', name.toLowerCase())
  );

  const querySnap = await getDocs(q);
  let unique = true;
  querySnap.forEach(doc => {
    unique = false;
  });

  return unique;
}

export const checkActiveDrawing = async (wallet) =>{
  if (!wallet) {
    return null
  }
  const expiration = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  const activeDrawing = 'activeDrawings';
  const q = query(
    collection(db, activeDrawing),
    where('wallet', '==', wallet.toLowerCase()),
    where('createdAt','>', expiration),
    orderBy('createdAt', 'desc'),
    limit(1)
  );
  const querySnap = await getDocs(q);
  let data = null;
  querySnap.forEach(doc => {
    data = {...doc.data()}
  })
  return data;
}

export const createActiveDrawingDoc = (wallet, pickleId, bgId, submitted) => {
  if (!wallet) return;
  const activeDrawing = 'activeDrawings';
  const data = {
    wallet: wallet.toLowerCase(),
    createdAt: serverTimestamp(),
    pickleId,
    bgId,
    submitted: submitted || null
  }
  addDoc(collection(db,activeDrawing), data)
}

export const isUniquePickleDrawing = async (pickleId) => {
  const submissions = 'submissions';
  const q = query(
    collection(db, submissions),
    where('pickleId', '==', pickleId)
  );
  const querySnap = await getDocs(q);
  return querySnap.length === 0;
}

export const getMyInvites = async (account) => {
  const invitationCollection = "invitations";
  const q = query(
    collection(db, invitationCollection),
    where('invitedBy', '==', account.toLowerCase())
  );
  const querySnap = await getDocs(q);
  const data = [];
  querySnap.forEach(doc => {
    data.push(doc.data());
  });
  return data;
}

export const isWalletAlreadyAllowed = async (account) => {
  const invited = await isInvited(account.toLowerCase());
  const simplePass = await getWhitelists(account.toLowerCase());
  const artistPass = await getArtistWhitelists(account.toLowerCase());
  const ultimatePass = await getUnlimitedPass(account.toLowerCase());

  return simplePass || artistPass || ultimatePass || invited;
}

export const createInvite = async (account, inviter) => {
  const docSnap = await setDoc(doc(db, "invitations",account.toLowerCase()), {
    invitedBy: inviter,
    createdAt: serverTimestamp(),
    invited: account,
  }).then(() => {
    console.log('invitation stored')
    return true;
  }).catch((e)=> {
    console.log('lalalla', e)
    throw new Error("Error while inviting")
  })
  return docSnap;
}

export const getFiveSubmissions = async () => {
  const docs = await getDocs(query(collection(db, "submissions"), where("avgVote", ">", 3.1),limit(10)));
  const data = [];
  docs.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}

export const submitNameTrait = async (data) => {
  const {account, ens, name, linkToTweet = null, discordId = null} = data;
  const docSnap = await setDoc(doc(db, "traitNameSubmissions",account.toLowerCase()), {
    account, ens, name, linkToTweet, discordId,
    createdAt: serverTimestamp(),
  }).then(() => {
    return true;
  }).catch((e)=> {
    console.log('lalalla', e)
    throw new Error("Error while inviting")
  })
  return docSnap;
}

export const getSubmission = async (id) => {
  const docData = await getDoc(doc(db,'submissions',id));

  return docData ? docData.data() : null;
}

export const getFeaturedSubmissions = async (lastSubmission) => {

  let q = query(collection(db, "submissions"), where('featuredExample'), orderBy("createdAt", "desc"), limit(15));
  if (lastSubmission) {
    q = query(collection(db, "submissions"), where('featuredExample'), orderBy("createdAt", "desc"),startAfter(lastSubmission), limit(15));
  }
  return getDocs(q);

}

export const getNewestSubmissions = async (lastSubmission) => {

  let q = query(collection(db, "submissions"),orderBy("createdAt", "desc"), limit(15));
  if (lastSubmission) {
    q = query(collection(db, "submissions"), orderBy("createdAt", "desc"),startAfter(lastSubmission), limit(15));
  }
  return getDocs(q);
}

export const getSubmissionsToJudge = async (id, account, vote) => {
  const docsRef = id ? await getDocs(query(collection(db,'submissions'),where('assignedNumber', '==', Number(id)))) : null;
  let q;
  if (id && docsRef?.docs[0]) {
    const docSnap = docsRef.docs[0];
    q = query(collection(db, "submissions"), orderBy("assignedNumber", "asc"), startAt(docSnap), limit(2));
  } else {
    const judgeRef = query(collection(db, "judgements"),where("judge", '==',account.toLowerCase()), orderBy("createdAt", "desc"), limit(1));
    const qSnap = await getDocs(judgeRef);
    let data = null;
    qSnap.forEach((item) => data=item);
    if (data) {
      const ref = await getDoc(doc(db,'submissions',data.data().submissionId)) 
      q = query(collection(db, "submissions"), orderBy("assignedNumber", "asc"), startAfter(ref), limit(2));
    } else {
      q = query(collection(db, "submissions"), orderBy("assignedNumber", "asc"), limit(2));
    }

  }
  const qSnap = await getDocs(q);
  const data = [];
  qSnap.forEach((item) => data.push(item.data()));
  return data;
}

export const createJudge = (submission, vote, account) => {
  const newJudgeRef = doc(db, "judgements", `${submission.id}-${account.toLowerCase()}`);
  return setDoc(newJudgeRef, {
    submissionId: submission.id,
    image: submission.image,
    name: submission.name,
    drawer: submission.ens || submission.wallet,
    judge: account.toLowerCase(),
    vote,
    createdAt: serverTimestamp(),
    assignedNumber: submission.assignedNumber
  });
}

export const getVotes = async (assignedNumber) => {
  const judgeRef = query(collection(db, "judgements"),where('assignedNumber', '==', Number(assignedNumber)));
  const qSnap = await getDocs(judgeRef);
  const data = [];
  qSnap.forEach(item => data.push(item.data()))
  return data;
}

export const getJudgeStats = async (account) => {
  const judgementRef = query(collection(db, "judgements"), where("judge", '==', account.toLowerCase()));
  const qSnap = await getDocs(judgementRef);
  return qSnap.docs.length;
}

export const increaseJudgesVotesCounter = async (account) => {
  updateDoc(doc(db, 'judges',account.toLowerCase()),{
    votes: increment(1),
  });
}

export const getJudgementsByVote = async (account, vote) => {
  const q = query(collection(db, 'judgements'), where('judge', '==', account.toLowerCase()), where('vote', '==', vote));
  const qSnap = await getDocs(q);
  const data = [];
  qSnap.forEach((docu) => {
    data.push(docu.data())
  })
  return data;
}

export const getSubmissionsByAvgVote = async (avg) => {
  const q = query(collection(db, 'submissions'), where('avgVote', '<=', avg), where ('avgVote', '>', (avg-1)|| 0));
  const qSnap = await getDocs(q);
  const data = [];
  qSnap.forEach((docu) => {
    data.push(docu.data())
  })
  return data;
}

export const updateSubmissionsAvgVote = (id) => {
  const q = query(collection(db, 'judgements'), where('submissionsId', '==',id));
  return getDocs(q)
  .then(async (resp) => {
    const dat = [];
    resp.forEach((item) => dat.push(item.data()));
    const avg = dat.reduce((partialSum, a) => partialSum + a.vote, 0)/dat.length;
    await updateDoc(doc(db, 'submissions',id),{
      avgVote:avg
    });
  });
}

export default db;
